import React, { useState } from 'react';

import { useSession } from 'service/session/hooks';
import ResellerUserList from './reseller';
import AdminUserList from './admin';
import Filter from './Filter';

function CustomersList() {
  const { roles: userRole } = useSession();
  const [view, setView] = useState('list');

  return (
    <React.Fragment>
      <Filter setView={setView} view={view} />
      {userRole.includes('Admin') && <AdminUserList />}
      {userRole.includes('Reseller') && <ResellerUserList />}
    </React.Fragment>
  );
}

export default CustomersList;
