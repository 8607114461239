import React from 'react';
import Favorites from 'features/Favorites';

import * as S from './styles';

function FavoritesPage() {
  return (
    <S.FavoritesPage>
      <Favorites />
    </S.FavoritesPage>
  );
}

export default FavoritesPage;
