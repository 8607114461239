import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { openErrorNotification } from 'common/helpers';

export const getReports = createAsyncThunk(
  'getReports',
  async (payload, { extra: { createAuthenticatedAPI }, rejectWithValue }) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.get('/report/detections/list', {
        params: payload,
      });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getReportsByGroup = createAsyncThunk(
  'getReportsByGroup',
  async (
    { payload, group },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.get(`/report/detections/list?group=${group}`, {
        params: payload,
      });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  reports: [],
  groupedReports: undefined,
  pending: false,
};

export const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReports.fulfilled, (state, { payload }) => {
        state.pending = false;
        state.reports = payload;
      })
      .addCase(getReports.pending, (state) => {
        state.pending = true;
      })
      .addCase(getReports.rejected, (state) => {
        openErrorNotification('Failed to get Reports.');
        state.pending = false;
      })
      .addCase(getReportsByGroup.fulfilled, (state, { payload }) => {
        state.pending = false;
        state.groupedReports = payload;
      })
      .addCase(getReportsByGroup.pending, (state) => {
        state.pending = true;
      })
      .addCase(getReportsByGroup.rejected, (state) => {
        openErrorNotification('Failed to get Grouped Reports.');
        state.pending = false;
      });
  },
});

export const selectReports = (state) => state.reports.reports;

export const selectGroupedReports = (state) => state.reports.groupedReports;

export const selectPending = (state) => state.reports.pending;

export default slice.reducer;
