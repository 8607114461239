import styled from 'styled-components';
import colors from 'styles/colors';

export const AuthenticatedPage = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;

  > aside {
    width: 230px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border: 1px solid ${colors.gray};
    background: ${colors.white};
    transition: 0.3s all ease-in-out;
    z-index: 10;
    background: #fff;

    @media (max-width: 900px) {
      transform: translate3d(-230px, 0, 0);
    }
    &.active {
      transform: translate3d(0, 0, 0);
    }
  }
  > section {
    position: absolute;
    top: 56px;
    left: 230px;
    width: calc(100% - 230px);
    min-height: calc(100vh - 56px);
    @media (max-width: 900px) {
      left: 0;
      width: 100%;
    }
  }
`;
