import styled from 'styled-components';
import colors from 'styles/colors';

export const OnboardingSlider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  > section {
    max-width: 600px;
    width: 100%;
  }
  .ant-carousel .slick-dots li button {
    background: ${colors.primary};
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -30px;
  }
`;

export const SliderItem = styled.div`
  width: 100%;
  text-align: center;

  img {
    width: 100%;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    color: #797979;
  }
`;
