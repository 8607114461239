import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { openErrorNotification } from 'common/helpers';

export const resellerUsersGet = createAsyncThunk(
  'reseller/users/get',
  async (
    { search },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      const { data } = await api.get('/reseller/customers', {
        params: { search },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  pendingUsersGet: false,
  users: [],
};

export const slice = createSlice({
  name: 'reseller',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resellerUsersGet.fulfilled, (state, { payload }) => {
        state.pendingUsersGet = false;
        state.users = payload;
      })
      .addCase(resellerUsersGet.pending, (state) => {
        state.pendingUsersGet = true;
      })
      .addCase(resellerUsersGet.rejected, (state) => {
        openErrorNotification('Failed to get Users.');
        state.pendingUsersGet = false;
      });
  },
});

export const selectPendingUsersGet = (state) => state.reseller.pendingUsersGet;
export const selectUsers = (state) => state.reseller.users;

export default slice.reducer;
