import React from 'react';
import { Button, Row, Col, Space, Typography } from 'antd';
import Box from 'components/Box';

import { CreditCardOutlined } from '@ant-design/icons';

import * as S from './styles';

const { Title } = Typography;

function DeviceSubscriptionCancel({ onCancel }) {
  return (
    <S.DeviceSubscriptionCancel>
      <Box p={{ _: 20, sm: 30 }}>
        <Space style={{ margin: '0 0 20px 0' }}>
          <CreditCardOutlined style={{ fontSize: '21px' }} />
          <Title level={4} style={{ margin: '0' }}>
            Cancel Subscription
          </Title>
        </Space>
        <Row>
          <Col span={24}>
            <p>Cancel Subscription eee</p>
          </Col>
        </Row>

        <Row gutter={10} style={{ marginTop: '10px' }}>
          <Col span={3}>
            <Button type="default" onClick={() => onCancel()} block>
              Back
            </Button>
          </Col>
          <Col span={10}>
            <Button type="primary" block>
              Cancel Subscription
            </Button>
          </Col>
          <Col span={11}>
            <Button type="primary" block>
              Cancel Immediately
            </Button>
          </Col>
        </Row>
      </Box>
    </S.DeviceSubscriptionCancel>
  );
}

export default DeviceSubscriptionCancel;
