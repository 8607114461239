import { useSelector, useDispatch } from 'react-redux';
import {
  selectReports,
  selectGroupedReports,
  selectPending,
  getReports as getReportsApi,
  getReportsByGroup as getReportsByGroupApi,
} from './slice';

export const useGetReports = () => {
  const dispatch = useDispatch();
  const reports = useSelector(selectReports);
  const loading = useSelector(selectPending);

  const getReports = (from, to) => {
    dispatch(getReportsApi({ from, to }));
  };

  return [getReports, { data: reports, loading }];
};

export const useGetGroupedReports = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectGroupedReports);
  const loading = useSelector(selectPending);

  const getReportsByGroup = ({ from, to, group }) => {
    dispatch(getReportsByGroupApi({ payload: { from, to }, group }));
  };

  return [getReportsByGroup, { data, loading }];
};

export default { useGetReports, useGetGroupedReports };
