import styled from 'styled-components';
import colors from 'styles/colors';

export const GuestNav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  border-bottom: 1px solid ${colors.gray};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;

  > section {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;
  }

  .mobile-nav {
    display: none;
    @media (max-width: 1000px) {
      display: block;
    }
  }
  .desktop-nav {
    display: block;
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .active {
    color: ${colors.secondary};
  }
`;
