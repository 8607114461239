import React, { useState } from 'react';
import Logo from 'components/Logo';
import { Button, Row, Col, Space, Drawer } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import nav from 'images/nav.svg';
import Box from 'components/Box';

import { useSession } from 'service/session/hooks';

import * as S from './styles';

function GuestNav() {
  const [visible, setVisible] = useState(false);
  const { isAuthenticated, logout, login } = useSession();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const showDrawer = () => setVisible(true);

  const onClose = () => setVisible(false);

  useUpdateEffect(() => {
    if (isAuthenticated) {
      push('/app/devices');
    }
  }, [isAuthenticated]);

  return (
    <S.GuestNav>
      <Box maxWidth={1400} width="100%" m="0 auto" px={4}>
        <Row justify="space-between" align="middle">
          <Col>
            <a href="https://imageengine.ai">
              <Logo />
            </a>
          </Col>
          <Col>
            <Box display={{ _: 'none', lg: 'block' }}>
              <Space size={20} style={{ marginRight: '20px' }} />
              {isAuthenticated ? (
                <Space>
                  <Link to="/app/devices">
                    <Button type="primary" ghost>
                      Go to My Devices
                    </Button>
                  </Link>
                  <Button
                    type="primary"
                    onClick={() => {
                      logout({
                        logoutParams: {
                          returnTo: process.env.REACT_APP_BASE_URL,
                        },
                      });
                    }}
                  >
                    Log Out
                  </Button>
                </Space>
              ) : (
                <Space>
                  <Button type="primary" onClick={login}>
                    {pathname === '/register'
                      ? 'I already have an account'
                      : 'Sign In'}
                  </Button>
                </Space>
              )}
            </Box>

            <Box display={{ _: 'block', lg: 'none' }} onClick={showDrawer}>
              <img src={nav} alt="nav" />
            </Box>
          </Col>
        </Row>
      </Box>
      <Drawer
        title="MENU"
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
        footer={
          <Box width="100%" display="flex" flexDirection="column">
            {pathname === '/register' ? (
              <Link to="/register">
                <Button
                  type="primary"
                  ghost
                  block
                  style={{ marginBottom: '10px' }}
                >
                  Register
                </Button>
              </Link>
            ) : (
              <Button
                type="primary"
                block
                style={{ marginBottom: '10px' }}
                onClick={login}
              >
                Sign In
              </Button>
            )}
          </Box>
        }
      />
    </S.GuestNav>
  );
}

export default GuestNav;
