import React from 'react';
import { StarOutlined } from '@ant-design/icons';

import * as S from './styles';

function Favorites() {
  return (
    <S.Favorites isEmpty>
      <S.Empty>
        <aside>
          <StarOutlined style={{ fontSize: '34px' }} />
        </aside>
        <p>You don’t have favorites :(</p>
        <small>
          Go to <strong>My Devices</strong> to <br />
          set any device as favorites
        </small>
      </S.Empty>
    </S.Favorites>
  );
}

export default Favorites;
