import styled from 'styled-components';
import colors from 'styles/colors';

export const SideNav = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;

  > section {
    width: 100%;
  }
  .logo {
    margin: auto;
    display: block;
  }
  .logo-icon {
    width: 24px;
    height: 24px;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.gray};
  height: 55px;
  width: 100%;
  padding: 0 25px;

  .logo-text {
    width: 86px;
  }
`;

export const NavList = styled.div`
  .add-btn {
    padding: 25px;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    > p {
      font-size: 13px;
      font-weight: 500;
      margin-left: 15px;

      &:hover {
        color: ${colors.primary} !important;
      }
    }
  }
  ul {
    width: 100%;
    li {
      .powered-by {
        display: flex;
        align-items: center;
        padding: 10px 25px;

        small {
          font-size: 10px;
        }
      }
      a {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px 25px;
        position: relative;
        &:hover {
          background: ${colors.gray_1};
        }
      }
      p {
        font-size: 13px;
        font-weight: 500;
        margin-left: 15px;
        color: ${colors.black};
      }
      .ant-icon {
        width: 24px;
      }
    }
    .active {
      border-right: 4px solid ${colors.primary};
      .anticon {
        color: ${colors.primary} !important;
      }
      p {
        color: ${colors.primary} !important;
      }
    }
  }
`;
