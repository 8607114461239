import React from 'react';
import logo from 'images/logo.svg';
import logoText from 'images/logo-text.svg';
import Box from 'components/Box';

function Logo() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <img src={logo} alt="logo" style={{ marginRight: '20px' }} />
      <img src={logoText} alt="logo-text" />
    </Box>
  );
}

export default Logo;
