import React from 'react';
import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';

import { Space, Modal, Button, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { createAuthenticatedAPI } from 'app/client';

import { useSession } from 'service/session/hooks';
import { handleClearTokens } from 'common/auth0';

import * as S from './styles';

dayjs.extend(relativeTime);

export function PopoverNotification({ list }) {
  const [notification, setNotification] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);

  const handleShowNotificationMessage = (item) => {
    setNotification(item);
    setShowModal(true);
  };

  const handleDeleteNotification = (notificationId) => {
    if (!notificationId) return;
    const api = createAuthenticatedAPI();
    api.delete(`/notification/list/${notificationId}`).catch();
  };

  return (
    <S.PopoverNotification>
      <Space direction="vertical" style={{ width: '100%' }}>
        {list.map((item) => {
          const { imageId, id: notificationId } = item;
          return (
            <React.Fragment key={notificationId}>
              {imageId ? (
                <Link
                  onClick={() => handleDeleteNotification(notificationId)}
                  to={`/app/devices/${item.deviceId}/photo/${imageId}`}
                >
                  <section>
                    <article>
                      <strong>{item.type}</strong>
                      <Typography.Text>{item.message}</Typography.Text>
                      <small>{dayjs(item.createdAt).fromNow()}</small>
                    </article>
                    <span className="dot" />
                  </section>
                </Link>
              ) : (
                <section
                  onClick={() => handleShowNotificationMessage(item)}
                  role="presentation"
                >
                  <article>
                    <strong>{item.type}</strong>
                    <Typography.Text>{item.message}</Typography.Text>
                    <small>{dayjs(item.createdAt).fromNow()}</small>
                  </article>
                  <span className="dot" />
                </section>
              )}
            </React.Fragment>
          );
        })}
      </Space>
      <Modal
        title={notification.type}
        centered
        open={showModal}
        closable
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <h4>Device</h4>
        {notification.deviceId}
        <br />
        <br />
        <h4>Message</h4>
        {notification.message}
        <br />
        <br />
        <h4>Date</h4>
        {dayjs(notification.createdAt).format('MMM-DD-YYYY hh:mm A')}
        <br />
        <br />
        {notification.message?.includes('No available AI check') && (
          <Button style={{ margin: '0 auto', display: 'block' }}>
            Purchase AI Check Credits
          </Button>
        )}
        {notification.message?.includes('Not enough storage') && (
          <Button style={{ margin: '0 auto', display: 'block' }}>
            Purchase Storage Credits
          </Button>
        )}
      </Modal>
    </S.PopoverNotification>
  );
}

export function PopoverProfile() {
  const { logout } = useSession();
  const handleLogout = (e) => {
    e.preventDefault();
    logout({ logoutParams: { returnTo: process.env.REACT_APP_BASE_URL } });

    handleClearTokens();
  };

  return (
    <S.PopoverProfile>
      <Space direction="vertical">
        <Link to="/app/settings/account">Account Settings</Link>
        <a href="/" onClick={handleLogout}>
          Logout
        </a>
      </Space>
    </S.PopoverProfile>
  );
}

export function PopoverDevice() {
  return (
    <S.PopoverDevice>
      <Space direction="vertical">
        <button type="button">View Device</button>
        <button type="button">Device Details</button>
        <button type="button">View Images</button>
        <button type="button">Add to Favorites</button>
        <button type="button">Sharing</button>
        <button type="button">Device Settings</button>
        <button type="button">Delete Device</button>
      </Space>
    </S.PopoverDevice>
  );
}
