import styled from 'styled-components';
import Box from 'components/Box';

export const StyledSavedPlateNumbers = styled(Box)`
  padding: 30px;
  width: 100%;

  .plate-numbers-form {
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: start;
    column-gap: 8px;
    margin-bottom: 20px;
  }
`;
