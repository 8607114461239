import styled from 'styled-components';
import colors from 'styles/colors';

export const DesktopNav = styled.div`
  width: 100%;
  padding: 20px;

  ul {
    margin-top: 20px;
  }
  strong {
    font-weight: 500;
    font-size: 13px;
    color: ${colors.black};
  }
  .nav-link {
    padding: 15px 10px;
    display: block;
    color: inherit;
  }
  .active {
    background: ${colors.gray_3};
    .anticon {
      color: ${colors.primary} !important;
    }
    strong {
      color: ${colors.primary} !important;
    }
  }
`;

export const MobileNav = styled.div`
  width: 100%;
  overflow: auto;
  border-bottom: 1px solid ${colors.gray};

  ul {
    display: flex;
    align-items: center;
  }
  strong {
    font-weight: 500;
    font-size: 13px;
    color: ${colors.black};
  }
  .nav-link {
    padding: 15px 10px;
    display: block;
    color: inherit;
  }
  .active {
    background: ${colors.gray_3};
    .anticon {
      color: ${colors.primary} !important;
    }
    strong {
      color: ${colors.primary} !important;
    }
  }
`;
