import React from 'react';
import CustomerDeviceList from 'features/CustomerDeviceList';

import * as S from './styles';

function CustomerDeviceListPage() {
  return (
    <S.CustomerDeviceListPage>
      <CustomerDeviceList />
    </S.CustomerDeviceListPage>
  );
}

export default CustomerDeviceListPage;
