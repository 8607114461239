import React from 'react';
import {
  Space,
  Typography,
  Radio,
  Divider,
  message,
  Select,
  Row,
  Slider,
  Button,
} from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { openSuccessNotification } from 'common/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { patchUser, selectUserMe } from 'features/Users/slice';
import styled from 'styled-components';
import Box from 'components/Box';

const { Title, Text } = Typography;

const Wrapper = styled(Box)`
  .ant-radio-wrapper {
    white-space: normal !important;
    height: auto !important;
    line-height: 25px;
  }
`;

function Storage() {
  const dispatch = useDispatch();
  const userMe = useSelector(selectUserMe);

  const [retentionRadio, setRetentionRadio] = React.useState('');
  const [imageRetention, setImageRetention] = React.useState('');
  const [confidenceLevel, setConfidenceLevel] = React.useState(10);

  const onChangeRetention = (e) => {
    const { value } = e.target;
    setRetentionRadio(value);
    message.loading('Updating settings...');
    dispatch(patchUser({ diskRetention: value })).then(() => {
      message.destroy();
      openSuccessNotification({
        message: 'Disk Retention Settings Updated!',
        description:
          value === 'Overwrite'
            ? 'Old images will be overridden to save newly upload images.'
            : 'All images will be saved and you will be notified if you are about to reach your storage limit',
      });
    });
  };

  const saveImageRetentionSettings = () => {
    message.loading('Updating settings...');
    dispatch(
      patchUser({ storageSetting: imageRetention, confidenceLevel }),
    ).then(() => {
      message.destroy();
      openSuccessNotification({
        message: 'Image Retention Settings Updated!',
        description: {
          'Save All': 'All images will be saved.',
          'By Device Parameter':
            'Images will be saved when it is above device assigned confidence level.',
          'By Confidence Level': `Images will be save when it is above ${confidenceLevel}% confidence level.`,
        }[imageRetention],
      });
    });
  };

  const handleChangeImageRetention = (value) => {
    setImageRetention(value);
  };

  useEffectOnce(() => {
    setRetentionRadio(userMe.diskRetention);
    setImageRetention(userMe.storageSetting);
    setConfidenceLevel(userMe.confidenceLevel);
  });

  useUpdateEffect(() => {
    setRetentionRadio(userMe.diskRetention);
    setImageRetention(userMe.storageSetting);
    setConfidenceLevel(userMe.confidenceLevel);
  }, [userMe]);

  return (
    <Wrapper p={{ _: 20, sm: 30 }}>
      <Space style={{ margin: '0 0 20px 0' }}>
        <DatabaseOutlined style={{ fontSize: '21px' }} />
        <Title level={4} style={{ margin: '0' }}>
          Storage
        </Title>
      </Space>
      <Title level={5} style={{ margin: '20px 0 0 0' }}>
        Disk Retention
      </Title>
      <Text type="secondary" style={{ marginBottom: '10px', display: 'block' }}>
        Manage disk space and save more storage.
      </Text>
      <Radio.Group onChange={onChangeRetention} value={retentionRadio}>
        <Radio value="Overwrite">
          Overwrite when full, replace oldest images when you run out of disk
          space
        </Radio>
        <Radio value="Save All">
          Save All and do not overwrite. Email me when disk available space at
          10%
        </Radio>
      </Radio.Group>
      <Divider />
      <Row justify="space-between" align="middle">
        <Space direction="vertical" size={1} style={{ marginBottom: '10px' }}>
          <Text strong>Image Retention</Text>
          <Text type="secondary">
            Select action to perform when a image does not match the AI
            triggers.
          </Text>
        </Space>
        <Select
          value={imageRetention}
          style={{ width: 400 }}
          onChange={handleChangeImageRetention}
        >
          <Select.Option value="Save All">Save All Images</Select.Option>
          <Select.Option value="By Device Parameter">
            Save Images that meet confidence level
          </Select.Option>
          <Select.Option value="By Confidence Level">
            Save Images with custom confidence level
          </Select.Option>
        </Select>
      </Row>
      {imageRetention === 'Save All' && (
        <Row
          justify="start"
          style={{ marginTop: '10px', color: '#aaa', fontStyle: 'italic' }}
        >
          Saves all images to storage regardless of their results from the AI
          analysis.
        </Row>
      )}
      {imageRetention === 'By Device Parameter' && (
        <Row
          justify="start"
          style={{ marginTop: '10px', color: '#aaa', fontStyle: 'italic' }}
        >
          Save all images where the result from the AI meets your AI parameters
          settings. Example: The AI Parameter is set for car to 90%. AI Tag or
          Object results include &ldquo;car&rdquo; and the confidence level is
          91%. The confidence level is greater than the AI parameter and the
          image is saved.
        </Row>
      )}
      {imageRetention === 'By Confidence Level' && (
        <Row justify="start" style={{ marginTop: '10px' }}>
          <Text
            style={{ marginBottom: '4px', color: '#aaa', fontStyle: 'italic' }}
          >
            This is a minimum setting for retaining images. For example, if an
            AI parameter of 50% is set for fire and the save images for X
            confidence level is set to 20% and the result from the AI is 29%.
            The image will be saved because it is over the minimum X confidence
            level even though it is under the AI parameter trigger level.
          </Text>
          <br />
          <Space>
            <Slider
              onChange={(val) => setConfidenceLevel(val)}
              value={confidenceLevel}
              style={{ width: '200px' }}
            />
            <span
              style={{
                width: '20px',
                textAlign: 'right',
                display: 'inline-block',
              }}
            >
              {confidenceLevel}%
            </span>
          </Space>
        </Row>
      )}
      <Row justify="start" style={{ marginTop: '10px' }}>
        <Button type="primary" onClick={saveImageRetentionSettings}>
          Save Image Retention Settings
        </Button>
      </Row>
    </Wrapper>
  );
}

export default Storage;
