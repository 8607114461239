import styled from 'styled-components';
import colors from 'styles/colors';

export const CustomerAdd = styled.div`
  width: 100%;
  background: ${colors.gray_1};
  padding: 20px;
  min-height: calc(100vh - 119px);
  width: 100%;
`;
