import React from 'react';
import { Space, Input } from 'antd';
import {
  FilterOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';

import * as S from './styles';

function PageFilter({ title }) {
  // eslint-disable-next-line no-console
  const onSearch = (value) => console.log(value);

  return (
    <S.PageFilter>
      <Space>
        <h2>{title}</h2>
        <Input.Search placeholder="Search..." onSearch={onSearch} />
        <FilterOutlined style={{ fontSize: '21px' }} onClick={() => {}} />
      </Space>
      <Space>
        <p>View</p>
        <UnorderedListOutlined
          style={{ fontSize: '21px' }}
          onClick={() => {}}
        />
        <AppstoreOutlined style={{ fontSize: '21px' }} onClick={() => {}} />
      </Space>
    </S.PageFilter>
  );
}

export default PageFilter;
