import styled from 'styled-components';
import {
  compose,
  space,
  color,
  typography,
  layout,
  position,
  flexbox,
} from 'styled-system';

const Text = styled.p(
  compose(space, color, typography, layout, position, flexbox),
);

export default Text;
