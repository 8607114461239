import React, { useState } from 'react';
import Box from 'components/Box';

import { useDispatch, useSelector } from 'react-redux';
import { selectUsers, adminUsersGet } from 'features/Admin/slice';
import { useEffectOnce } from 'react-use';
import Filter from './Filter';
import ListView from './ListView';
import GridView from './GridView';

function AdminUsersList() {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const [view, setView] = useState('list');

  useEffectOnce(() => {
    dispatch(adminUsersGet({ search: '' }));
  });

  return (
    <React.Fragment>
      <Filter setView={setView} view={view} />
      <Box
        width="100%"
        backgroundColor="gray.1"
        p={4}
        mt={63}
        minHeight="calc(100vh - 119px)"
      >
        {view === 'grid' ? (
          <GridView list={users} />
        ) : (
          <ListView list={users} />
        )}
      </Box>
    </React.Fragment>
  );
}

export default AdminUsersList;
