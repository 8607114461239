import React, { useState } from 'react';
import { pullAllBy, filter } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { Space, Row, Col, Menu, Dropdown, Modal, Spin } from 'antd';
import {
  InfoCircleOutlined,
  SettingOutlined,
  CloseOutlined,
  RobotFilled,
} from '@ant-design/icons';
import DeviceGroup from 'features/DeviceManage/components/DeviceDetailsUpdate';
import DeviceAddOn from 'features/DeviceAddOn';
import DeviceSubscriptionCancel from 'features/DeviceSubscriptionCancel';
import DeviceSubscription from 'features/DeviceSubscription';
import useDeviceSubscription from 'features/DeviceSubscription/hooks';
import Text from 'components/Text';
import Box from 'components/Box';

import { retrieveDevice, deleteDevice } from 'features/DeviceDetail/slice';

import { deviceSubscriptionDeleteImmediately } from 'features/DeviceSubscription/slice';
import { sortByName } from 'common/helpers';

import { useSession } from 'service/session/hooks';
import * as S from './styles';
import InfoDrawer from './InfoDrawer';
import {
  customerDevicesGet,
  selectAllCustomerDevices,
  selectSearch,
  selectPending,
  selectShowActiveOnly,
} from './slice';

import Filters from './Filters';

function CustomerDeviceList() {
  const dispatch = useDispatch();
  const showActiveOnly = useSelector(selectShowActiveOnly);
  const searchKey = useSelector(selectSearch);
  const loading = useSelector(selectPending);
  const allDevices = useSelector(selectAllCustomerDevices);
  const { roles: userRole } = useSession();
  const { showModal: showSubscriptionModal, isModalActive } =
    useDeviceSubscription();
  const { customerId } = useParams();

  const [filteredDevice, setFilteredDevice] = useState([]);
  const [showDeviceGroupModal, setShowDeviceGroupModal] = useState(false);
  const [showDeviceAddOnModal, setShowDeviceAddOnModal] = useState(false);
  const [
    showDeviceSubscriptionCancelModal,
    setShowDeviceSubscriptionCancelModal,
  ] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({ id: '' });
  const [currentViewedDevice, setCurrentViewedDevice] = useState(null);
  const [isDrawerActive, setDrawerActive] = useState(false);
  const [showStorage, setShowStorage] = useState(false);

  const handleShowDeviceSubscription = (deviceId, displayStorage) => {
    setSelectedDevice(deviceId);
    setShowStorage(displayStorage);
    showSubscriptionModal(true);
  };

  const handleShowDeviceAddOns = (device) => {
    setSelectedDevice(device);
    setShowDeviceAddOnModal(true);
  };

  const handleShowDeviceGroup = (deviceId) => {
    setSelectedDevice(deviceId);
    setShowDeviceGroupModal(true);
  };

  const handleViewDeviceDetails = (device) => {
    setCurrentViewedDevice(device);
    dispatch(retrieveDevice(device));
    setDrawerActive(true);
  };

  const handleCancelSubscription = (device) => {
    const { subscriptionId, subscriptionCurrentDevice } = device;
    Modal.confirm({
      title: 'Confirm Subscription Cancellation',
      content:
        subscriptionCurrentDevice > 1 ? (
          <p>
            Are you sure you want to cancel this subscription?
            <br />
            <br />
            All photos from all connected device will be deleted permanently.
            <br />
            <br />
            You currently have <b>{subscriptionCurrentDevice} Devices</b> under
            this subscription.
          </p>
        ) : (
          'Are you sure you want to cancel this subscription? All photos from this device will be deleted permanently'
        ),
      onOk: async () => {
        await dispatch(deviceSubscriptionDeleteImmediately({ subscriptionId }));
        await dispatch(customerDevicesGet());
      },
      okText: 'Cancel Subscription',
    });
  };

  const handleDeleteDevice = (device) => {
    const { subscriptionId, id: deviceId } = device;
    Modal.confirm({
      title: 'Confirm Delete Device',
      content:
        'Are you sure you want to delete this device? All photos on this device will be deleted.',
      onOk: async () => {
        await dispatch(deleteDevice({ subscriptionId, deviceId }));
        await dispatch(customerDevicesGet());
      },
      okText: 'Delete Device and Photos',
    });
  };

  const groupBy = (previous, current) => {
    const existing = previous.find(
      (value) => value.id === current.deviceGroupId,
    );
    if (existing) {
      existing.devices.push(current);
    } else {
      previous.push({
        id: current.deviceGroupId,
        name: current.deviceGroupName,
        devices: [current],
      });
    }
    return previous;
  };

  const groupedDevices = filteredDevice.reduce(groupBy, []).sort(sortByName);

  useEffectOnce(() => {
    dispatch(customerDevicesGet({ customerId }));
  });

  useUpdateEffect(() => {
    const filteredDevices = filter(allDevices, (device) => {
      if (showActiveOnly) {
        return (
          device.name?.toLowerCase().includes(searchKey?.toLowerCase()) &&
          device.status !== 'Offline'
        );
      }
      return device.name?.toLowerCase().includes(searchKey?.toLowerCase());
    });

    const filteredAll = pullAllBy([...filteredDevices], 'id');

    setFilteredDevice(filteredAll);
  }, [allDevices, searchKey]);

  return (
    <React.Fragment>
      <Filters />
      <Spin spinning={loading}>
        <S.Devices>
          {groupedDevices.map((item) => {
            return (
              <S.Device key={item?.id}>
                <Text as="h5" fontSize={16} mb={4} fontWeight={600}>
                  {item.name}
                </Text>
                <Row gutter={[20, 20]}>
                  {item.devices.map((device) => {
                    const AICredits =
                      device.subscriptionConsumableAiChecks +
                      device.subscriptionMaxAiChecks -
                      device.subscriptionCurrentAiChecks;
                    return (
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 6 }}
                        key={device?.id}
                      >
                        <Box
                          backgroundColor="white"
                          borderRadius={8}
                          p={{ _: '10px 15px', sm: '15px 20px' }}
                        >
                          <Row justify="space-between">
                            <Col>
                              {/* <Link to={`/app/devices/${device.id}`}> */}
                              <Text fontWeight={500} color="black">
                                {device.name}
                              </Text>
                              {/* </Link> */}
                            </Col>
                            <Col>
                              <Space size={10}>
                                {[
                                  'Platinum',
                                  'Gold',
                                  'Silver',
                                  'Bronze',
                                ].includes(device.subscriptionType) &&
                                  AICredits > 0 && (
                                    <RobotFilled
                                      title={`Remaining AI Credits : ${AICredits}`}
                                      style={{
                                        fontSize: '21px',
                                        color: '#262E65',
                                      }}
                                    />
                                  )}
                                <InfoCircleOutlined
                                  title="Device Details"
                                  style={{ fontSize: '21px' }}
                                  onClick={() =>
                                    handleViewDeviceDetails(device)
                                  }
                                />
                                {device.deviceUserAllowConfigureAi ||
                                device.deviceUserAllowManageUser ||
                                device.isOwner ? (
                                  <Dropdown
                                    overlay={
                                      <Menu>
                                        {device.isOwner !== 0 &&
                                          !userRole.includes('Customer') && (
                                            <React.Fragment>
                                              <React.Fragment>
                                                {device.deviceUserAllowConfigureAi !==
                                                  0 &&
                                                  device.subscriptionType ===
                                                    'Platinum' && (
                                                    <Menu.Item
                                                      key="3"
                                                      onClick={() =>
                                                        handleShowDeviceAddOns(
                                                          device,
                                                        )
                                                      }
                                                    >
                                                      Add Ons
                                                    </Menu.Item>
                                                  )}
                                              </React.Fragment>
                                              <React.Fragment>
                                                {(device.subscriptionType ===
                                                  'Platinum' ||
                                                  device.subscriptionType ===
                                                    'Copper') && (
                                                  <Menu.Item
                                                    key="4"
                                                    onClick={() =>
                                                      handleShowDeviceSubscription(
                                                        device.id,
                                                        true,
                                                      )
                                                    }
                                                  >
                                                    Change Storage
                                                  </Menu.Item>
                                                )}
                                              </React.Fragment>
                                              <Menu.Item
                                                key="6"
                                                onClick={() =>
                                                  handleShowDeviceSubscription(
                                                    device.id,
                                                    false,
                                                  )
                                                }
                                              >
                                                Change Subscription
                                              </Menu.Item>
                                              <Menu.Item
                                                key="7"
                                                onClick={() =>
                                                  handleCancelSubscription(
                                                    device,
                                                  )
                                                }
                                              >
                                                Cancel Subscription
                                              </Menu.Item>
                                            </React.Fragment>
                                          )}
                                        {device.isOwner !== 0 && (
                                          <React.Fragment>
                                            <Menu.Item
                                              key="9"
                                              onClick={() =>
                                                handleShowDeviceGroup(device.id)
                                              }
                                            >
                                              Change Details
                                            </Menu.Item>

                                            <Menu.Item
                                              key="8"
                                              onClick={() =>
                                                handleDeleteDevice(device)
                                              }
                                            >
                                              Delete Device
                                            </Menu.Item>
                                          </React.Fragment>
                                        )}
                                      </Menu>
                                    }
                                    trigger={['click']}
                                  >
                                    <SettingOutlined
                                      title="Settings"
                                      style={{ fontSize: '21px' }}
                                    />
                                  </Dropdown>
                                ) : null}
                              </Space>
                            </Col>
                          </Row>
                        </Box>
                      </Col>
                    );
                  })}
                </Row>
              </S.Device>
            );
          })}
          <InfoDrawer
            device={currentViewedDevice}
            isDrawerActive={isDrawerActive}
            setDrawerActive={setDrawerActive}
          />

          <Modal
            destroyOnClose
            open={showDeviceGroupModal}
            footer={null}
            width={600}
            closeIcon={
              <CloseOutlined onClick={() => setShowDeviceGroupModal(false)} />
            }
          >
            <DeviceGroup deviceId={selectedDevice} />
          </Modal>
          <Modal
            destroyOnClose
            open={isModalActive}
            footer={null}
            width={600}
            closeIcon={
              <CloseOutlined onClick={() => showSubscriptionModal(false)} />
            }
          >
            <DeviceSubscription
              showStorage={showStorage}
              deviceId={selectedDevice}
            />
          </Modal>

          <Modal
            destroyOnClose
            open={showDeviceAddOnModal}
            footer={null}
            width={600}
            closeIcon={
              <CloseOutlined onClick={() => setShowDeviceAddOnModal(false)} />
            }
          >
            <DeviceAddOn
              device={selectedDevice}
              onPurchase={() => setShowDeviceAddOnModal(false)}
            />
          </Modal>

          <Modal
            destroyOnClose
            open={showDeviceSubscriptionCancelModal}
            footer={null}
            width={700}
            closeIcon={
              <CloseOutlined
                onClick={() => setShowDeviceSubscriptionCancelModal(false)}
              />
            }
          >
            <DeviceSubscriptionCancel
              device={selectedDevice}
              onCancel={() => setShowDeviceSubscriptionCancelModal(false)}
            />
          </Modal>
        </S.Devices>
      </Spin>
    </React.Fragment>
  );
}

export default CustomerDeviceList;
