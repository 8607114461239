import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Space, Button, Image, Spin } from 'antd';
import {
  CloudDownloadOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import Box from 'components/Box';
import Text from 'components/Text';

import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce, useUpdateEffect, useMount } from 'react-use';
import { useParams } from 'react-router-dom';

import {
  retrieveDevicePhoto,
  selectPhoto,
  selectRectangle,
  selectPendingPhoto,
  setRectangle,
} from '../slice';

import * as S from '../styles';
import Detections from './Detections';

function Thumbnail({ device }) {
  const dispatch = useDispatch();
  // const history = useHistory();

  const { id, photoId } = useParams();
  const photo = useSelector(selectPhoto);

  const rectangle = useSelector(selectRectangle);
  const pendingPhoto = useSelector(selectPendingPhoto);
  // const pendingDeletePhoto = useSelector(selectPendingDeletePhoto);
  const [redBoxStyle, setRedBoxStyle] = useState({
    height: 0,
    width: 0,
    top: 0,
    left: 0,
  });

  const [imageDimension, setImageDimension] = useState({});

  // const handleDeletePhoto = async () => {
  //   await dispatch(deleteDevicePhoto({ deviceId: id, id: photoId }));
  //   const { payload: devicePhotos } = await dispatch(retrieveDevicePhotos({ id }));
  //   if (devicePhotos[0]) {
  //     history.push(`/app/devices/${devicePhotos[0].deviceId}/photo/${devicePhotos[0].id}`);
  //   } else {
  //     history.push(`/app/devices/${devicePhotos[0].deviceId}`);
  //   }
  // };

  const handleRetrievePhoto = (deviceId, imageId) => {
    if (deviceId && imageId) {
      dispatch(setRectangle({}));
      dispatch(retrieveDevicePhoto({ deviceId, photoId: imageId }));
    }
  };

  const ro = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      const { height, width } = entry.contentRect;
      setImageDimension({ height, width });
    });
  });

  // Only observe the second box
  useMount(() => ro.observe(document.querySelector('.photo-thumbnail')));

  useUpdateEffect(() => {
    // rectangle can start at location 0
    const redBox = {
      height: `${(imageDimension.height / photo.height) * rectangle.h}px`,
      width: `${(imageDimension.width / photo.width) * rectangle.w}px`,
      top: `${(imageDimension.height / photo.height) * rectangle.y}px`,
      left: `${(imageDimension.width / photo.width) * rectangle.x}px`,
      display:
        rectangle.x || rectangle.y || rectangle.h || rectangle.w
          ? 'inherit'
          : 'none',
    };

    setRedBoxStyle(redBox);
  }, [rectangle, imageDimension]);

  useUpdateEffect(() => {
    handleRetrievePhoto(id, photoId);
  }, [id, photoId]);

  useEffectOnce(() => {
    handleRetrievePhoto(id, photoId);
  });

  return (
    <Spin spinning={pendingPhoto}>
      <S.Thumbnail>
        <figure>
          <div className="rectangle" style={redBoxStyle} />
          <Image
            className="photo-thumbnail"
            src={photo.previewUrl}
            preview={
              device?.allowDownload && photo.previewUrl !== ''
                ? { src: photo.url }
                : false
            }
            width="100%"
          />
          <div style={{ padding: '10px', textAlign: 'center' }}>
            {photo.fileName}
          </div>
        </figure>
        {photo.id && (
          <section>
            <Box display={{ _: 'none', sm: 'block' }}>
              <Space>
                {device?.allowDownload !== 0 && (
                  <Button
                    type="default"
                    href={photo.url}
                    target="_blank"
                    icon={<CloudDownloadOutlined />}
                  >
                    Download Image
                  </Button>
                )}

                {/* TODO */}
                {/* <Button */}
                {/*  type="default" */}
                {/*  loading={pendingDeletePhoto} */}
                {/*  onClick={() => handleDeletePhoto()} */}
                {/*  icon={<DeleteOutlined />} */}
                {/* > */}
                {/*  Delete */}
                {/* </Button> */}
              </Space>
            </Box>
            <Box display={{ _: 'block', sm: 'none' }}>
              <Space>
                <CloudDownloadOutlined style={{ fontSize: '18px' }} />
                <DeleteOutlined style={{ fontSize: '18px' }} />
              </Space>
            </Box>
            <Space size={20}>
              <aside>
                <ClockCircleOutlined />
                <Text fontSize={{ _: 12, sm: 14 }}>
                  {dayjs(photo.uploadTimeAt || photo.updatedAt).format(
                    'MMM DD, YYYY - ddd hh:mm:ss A',
                  )}
                </Text>
              </aside>
            </Space>
          </section>
        )}
      </S.Thumbnail>
      <Detections />
    </Spin>
  );
}

export default Thumbnail;
