import React from 'react';
import Box from 'components/Box';
import Text from 'components/Text';
import { Button, Popover, List, Space } from 'antd';
import {
  VideoCameraAddOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const MOCK_DATA = [
  {
    id: 1,
    name: 'Reston Road Cam',
  },
  {
    id: 2,
    name: 'Front Store Cam',
  },
  {
    id: 3,
    name: 'Storage Cam',
  },
];

function Step2() {
  const history = useHistory();

  const PopoverContent = () => {
    return (
      <Box display="flex" flexDirection="column">
        <Button type="text">Edit Device</Button>
        <Button type="text">Device Details</Button>
        <Button type="text">Delete Device</Button>
      </Box>
    );
  };

  return (
    <Box
      maxWidth={670}
      width="100%"
      backgroundColor="white"
      border="1px solid"
      borderColor="gray"
      m="auto"
      py={35}
      px={100}
    >
      <Text as="h4" fontWeight={600} fontSize={18} mb={2}>
        Manage Devices (Optional)
      </Text>
      <Text fontSize={13} mb={6}>
        Information about your customer whether is it an individual or company
        and select their desired plan.
      </Text>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Button
          type="text"
          icon={<VideoCameraAddOutlined style={{ fontSize: '18px' }} />}
          size="large"
        >
          Add Device
        </Button>
        <Button
          type="text"
          icon={<DownloadOutlined style={{ fontSize: '18px' }} />}
          size="large"
        >
          Download Summary
        </Button>
      </Box>
      <Box mb={4}>
        <Box display="flex" justifyContent="space-between" pr={17}>
          <Text fontWeight={600} fontSize={14} mb={2}>
            Sunny Warehouse
          </Text>
          <Popover content={PopoverContent} placement="bottom" trigger="click">
            <EllipsisOutlined style={{ fontSize: '21px' }} />
          </Popover>
        </Box>
        <List
          size="small"
          bordered
          dataSource={MOCK_DATA}
          renderItem={(item) => (
            <List.Item>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center">
                  <VideoCameraOutlined />
                  <Text ml={2} fontSize={13} fontWeight={600}>
                    {item.name}
                  </Text>
                </Box>
                <Popover
                  content={PopoverContent}
                  placement="bottom"
                  trigger="click"
                >
                  <EllipsisOutlined style={{ fontSize: '21px' }} />
                </Popover>
              </Box>
            </List.Item>
          )}
        />
      </Box>
      <Box mb={4}>
        <Box display="flex" justifyContent="space-between" pr={17}>
          <Text fontWeight={600} fontSize={14} mb={2}>
            New Telware House
          </Text>
          <Popover content={PopoverContent} placement="bottom" trigger="click">
            <EllipsisOutlined style={{ fontSize: '21px' }} />
          </Popover>
        </Box>
        <List
          size="small"
          bordered
          dataSource={MOCK_DATA}
          renderItem={(item) => (
            <List.Item>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center">
                  <VideoCameraOutlined />
                  <Text ml={2} fontSize={13} fontWeight={600}>
                    {item.name}
                  </Text>
                </Box>
                <Popover
                  content={PopoverContent}
                  placement="bottom"
                  trigger="click"
                >
                  <EllipsisOutlined style={{ fontSize: '21px' }} />
                </Popover>
              </Box>
            </List.Item>
          )}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Space>
          <Button type="default" size="large">
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={() => history.push('/app/customers/new/step-3')}
          >
            Next
          </Button>
        </Space>
      </Box>
    </Box>
  );
}

export default Step2;
