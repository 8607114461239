import React from 'react';

import { useUpdateEffect } from 'react-use';
import { Select, Typography, Divider, Modal, notification, Button } from 'antd';
import { RobotOutlined } from '@ant-design/icons';
import useDeviceList from 'features/DeviceList/hooks';
import DeviceDetailUpdate from 'features/DeviceManage/components/DeviceDetailsUpdate';
import ChangePassword from 'features/DeviceManage/components/ChangePassword';
import { useDispatch } from 'react-redux';
import {
  deviceDelete,
  deviceSubscriptionCancel,
} from 'features/DeviceSubscription/slice';
import { devicesGet } from 'features/DeviceList/slice';

const { Option } = Select;

function DeviceManage({ deviceId }) {
  const dispatch = useDispatch();
  const { allDevices } = useDeviceList();
  const [selectedDeviceId, setSelectedDeviceId] = React.useState(deviceId);

  useUpdateEffect(() => {
    if (allDevices.length > 0) {
      setSelectedDeviceId(deviceId || allDevices[0].id);
    }
  }, [allDevices, deviceId]);

  const handleDeleteDevice = (device) => {
    if (device.subscriptionCurrentDevice === 1) {
      const { subscriptionId } = device;
      Modal.confirm({
        title: 'Last Device on Subscription',
        content:
          'Deleting this device will cancel your subscription. Are you sure you want to delete this device and cancel this subscription? All photos from this device will be deleted permanently',
        onOk: async () => {
          const response = await dispatch(
            deviceSubscriptionCancel({ subscriptionId }),
          );

          if (response.payload && !response.payload.error) {
            await dispatch(devicesGet());
            notification.info({
              message: 'Cancel Subscription',
              description:
                'Subscription is now for cancellation. You can still revert this action on subscription settings page before the end of the billing cycle.',
              duration: 10,
            });
          }
        },
        okText: 'Cancel Subscription',
      });
    } else {
      Modal.confirm({
        title: 'Confirm Delete Device',
        content:
          'Are you sure you want to delete this device? All photos on this device will be deleted.',
        onOk: async () => {
          const response = await dispatch(
            deviceDelete({ deviceId: device.id }),
          );

          if (response.payload && !response.payload.error) {
            await dispatch(devicesGet());
            notification.info({
              message: 'Device Deleted',
              description: 'All photos on this device has been deleted.',
              duration: 10,
            });
          }
        },
        okText: 'Delete Device and Photos',
      });
    }
  };

  return (
    <div className="relative flex min-h-[calc(100vh-110px)] flex-col px-[20px]">
      {!deviceId && allDevices.length > 0 && (
        <div className="flex flex-col gap-y-3">
          <div className="flex items-center gap-x-2 align-middle">
            <RobotOutlined style={{ fontSize: '21px' }} />
            <Typography.Title level={4} style={{ margin: '0' }}>
              Device Sharing
            </Typography.Title>
          </div>

          <Select
            onChange={(val) => setSelectedDeviceId(val)}
            defaultValue={allDevices[0]?.id}
            style={{ width: '100%', marginBottom: '20px' }}
          >
            {allDevices.map((device) => {
              if (
                device.isOwner === 1 ||
                device.deviceUserAllowManageUser === 1
              ) {
                return (
                  <Option value={device.id} key={device.id}>
                    {`[${device.deviceGroupName}] - ${device.name} - ${device.description}`}
                  </Option>
                );
              }
              return null;
            })}
          </Select>
        </div>
      )}
      <div className="flex flex-col gap-y-2 align-middle">
        <Divider orientation="left">Update Details</Divider>
        <DeviceDetailUpdate deviceId={selectedDeviceId} />
        <Divider orientation="left">Change Device FTP Password</Divider>
        <ChangePassword deviceId={selectedDeviceId} />
        <Divider orientation="left">Delete Device</Divider>
        <div className="flex w-full flex-col gap-y-2">
          <p className="mb-[20px] w-full text-gray-400">
            Deleting this device will also delete all photos from this device.
          </p>
          <div className="flex w-full justify-end">
            <Button onClick={handleDeleteDevice} danger>
              Delete Device
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceManage;
