import React from 'react';
import colors from 'styles/colors';
import logo from 'images/logo.svg';
import logoImageEngine from 'images/image-engine-logo.png';
import { NavLink } from 'react-router-dom';
import {
  PlusCircleFilled,
  SettingOutlined,
  VideoCameraOutlined,
  MenuFoldOutlined,
  ClusterOutlined,
  ProfileOutlined,
  NotificationOutlined,
  AreaChartOutlined,
  AlertOutlined,
} from '@ant-design/icons';
import useAddDevice from 'features/DeviceAddDrawer/hooks';
import Box from 'components/Box';

import { useSession } from 'service/session/hooks';
import * as S from './styles';

function SideNav({ setSideNav }) {
  const { showModal } = useAddDevice();
  const { roles: userRole } = useSession();

  return (
    <S.SideNav>
      <section>
        <S.Logo>
          <Box display={{ _: 'block', md: 'none' }} mr={3}>
            <MenuFoldOutlined
              onClick={() => setSideNav(false)}
              style={{ fontSize: '24px', color: colors.black }}
            />
          </Box>
          <img src={logoImageEngine} alt="logo" className="logo" />
        </S.Logo>
        <S.NavList>
          {!userRole.includes('Customer') && (
            <button
              type="button"
              className="add-btn"
              onClick={() => showModal(true)}
            >
              <PlusCircleFilled
                style={{ fontSize: '24px', color: colors.primary }}
              />
              <p>New Device</p>
            </button>
          )}
          <ul className="mt-2">
            <li>
              <NavLink to="/app/devices" activeClassName="active">
                <VideoCameraOutlined
                  style={{ fontSize: '24px', color: colors.gray_2 }}
                />
                <p>My Devices</p>
              </NavLink>
            </li>
            {/* TODO */}
            {/* <li> */}
            {/*  <NavLink to="/app/gallery" activeClassName="active"> */}
            {/*    <PictureOutlined */}
            {/*      style={{ fontSize: '24px', color: colors.gray_2 }} */}
            {/*    /> */}
            {/*    <p>Gallery</p> */}
            {/*  </NavLink> */}
            {/* </li> */}
            {(userRole.includes('Admin') || userRole.includes('Reseller')) && (
              <li>
                <NavLink to="/app/customers" activeClassName="active">
                  <ClusterOutlined
                    style={{ fontSize: '24px', color: colors.gray_2 }}
                  />
                  <p> Customers</p>
                </NavLink>
              </li>
            )}
            {(userRole.includes('Reseller') || userRole.includes('User')) && (
              <li>
                <NavLink to="/app/transactions" activeClassName="active">
                  <ProfileOutlined
                    style={{ fontSize: '24px', color: colors.gray_2 }}
                  />
                  <p>Transactions</p>
                </NavLink>
              </li>
            )}
            <li>
              <NavLink to="/app/statistics" activeClassName="active">
                <AreaChartOutlined
                  style={{ fontSize: '24px', color: colors.gray_2 }}
                />
                <p>Statistics</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/app/settings" activeClassName="active">
                <SettingOutlined
                  style={{ fontSize: '24px', color: colors.gray_2 }}
                />
                <p>Settings</p>
              </NavLink>
            </li>
            {/* <li> */}
            {/*  <NavLink to="/app/checkout" activeClassName="active"> */}
            {/*    <CreditCardOutlined */}
            {/*      style={{ fontSize: '24px', color: colors.gray_2 }} */}
            {/*    /> */}
            {/*    <p>Sample CheckOut</p> */}
            {/*  </NavLink> */}
            {/* </li> */}
          </ul>
        </S.NavList>
      </section>
      <footer>
        <S.NavList>
          <ul>
            <li>
              <a
                href="https://imageengine.upvoty.com/b/feature-requests/"
                rel="noreferrer"
                target="_blank"
              >
                <NotificationOutlined
                  style={{ fontSize: '24px', color: colors.gray_2 }}
                />
                <p>Feature Request</p>
              </a>
              {/* <NavLink to="/app/help" activeClassName="active"> */}
              {/*  */}
              {/* </NavLink> */}
            </li>
            <li>
              <a
                href="https://imageengine.ai/support/"
                rel="noreferrer"
                target="_blank"
              >
                <AlertOutlined
                  style={{ fontSize: '24px', color: colors.gray_2 }}
                />
                <p>Help and Support</p>
              </a>
              {/* <NavLink to="/app/help" activeClassName="active"> */}
              {/*  */}
              {/* </NavLink> */}
            </li>
            <li>
              <div className="powered-by">
                <img
                  src={logo}
                  className="logo-icon"
                  alt="logo"
                  style={{ marginRight: '15px' }}
                />
                <small>
                  App Ver. 1.0.0 <br />
                  Powered by ImageEngine.ai
                </small>
              </div>
            </li>
          </ul>
        </S.NavList>
      </footer>
    </S.SideNav>
  );
}

export default SideNav;
