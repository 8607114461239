import React from 'react';
import { Space, Typography, Button, Form, Input, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DollarOutlined, LockOutlined } from '@ant-design/icons';

import Box from 'components/Box';
import {
  adminResellerCreate,
  selectPendingResellerCreate,
} from 'features/Admin/slice';

const { Title } = Typography;
const REQUIRED = [{ required: true, message: 'This field is required!' }];
const PASSWORD = [
  { required: true, message: 'This field is required!' },
  { min: 8, message: 'Must be 8 characters and above.' },
];

function ResellerAdd({ onCreate }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loading = useSelector(selectPendingResellerCreate);
  const handleAddReseller = async (values) => {
    await dispatch(adminResellerCreate(values));
    form.resetFields();
    onCreate();
  };

  return (
    <Box p={{ _: 20, sm: 30 }}>
      <Space style={{ margin: '0 0 20px 0' }}>
        <DollarOutlined style={{ fontSize: '21px' }} />
        <Title level={4} style={{ margin: '0' }}>
          Create Reseller Account
        </Title>
      </Space>

      <Form form={form} onFinish={handleAddReseller} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item label="Email" name="email" rules={REQUIRED}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={REQUIRED}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Password" name="password" rules={PASSWORD}>
              <Input.Password prefix={<LockOutlined />} type="password" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button loading={loading} type="primary" htmlType="submit" block>
              Create Account
            </Button>
          </Col>
        </Row>
      </Form>
    </Box>
  );
}

export default ResellerAdd;
