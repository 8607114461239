import React from 'react';
import { Row, Col, Avatar, Button } from 'antd';
import { EditOutlined, ExportOutlined, PlusOutlined } from '@ant-design/icons';
import Box from 'components/Box';
import Text from 'components/Text';
import { renderPlanTag } from 'common/helpers';

const MOCK_DATA = [];
function GridView() {
  return (
    <Row gutter={[20, 20]}>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 12 }}
        xl={{ span: 8 }}
        xxl={{ span: 4 }}
      >
        <Box
          height={295}
          width="100%"
          border="1px solid"
          borderColor="gray"
          backgroundColor="white"
          borderRadius={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={3}
        >
          <Button
            type="primary"
            shape="circle"
            size="large"
            icon={<PlusOutlined />}
            danger
          />
          <Text mt={2} fontSize={15} fontWeight={600}>
            Add New Customer
          </Text>
          <Text mt={1} fontSize={13} color="gray_2" textAlign="center">
            Create new customer in your account where you can set plans and
            pricing.
          </Text>
        </Box>
      </Col>
      {MOCK_DATA.map((i) => (
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          xl={{ span: 8 }}
          xxl={{ span: 4 }}
          key={i.id}
        >
          <Box
            height={295}
            width="100%"
            border="1px solid"
            borderColor="gray"
            backgroundColor="white"
            borderRadius={4}
            display="flex"
            flexDirection="column"
          >
            <Box p={3}>
              <Text fontSize={15} fontWeight={600}>
                {i.name}
              </Text>
              <Text color="gray_2" fontSize={11} fontWeight={600} mb={2}>
                {i.id}
              </Text>
              {renderPlanTag(i.plan)}
              <Text color="gray_2" fontSize={13} mt={2}>
                # of cameras: {i.cameras}
              </Text>
              <Text color="gray_2" fontSize={13}>
                Date Started: {i.dateStarted}
              </Text>
            </Box>
            <Box
              borderTop="1px solid"
              borderBottom="1px solid"
              borderTopColor="gray"
              borderBottomColor="gray"
              py={2}
              px={3}
            >
              <Text color="gray_2" fontSize={13}>
                Users
              </Text>
              <Avatar.Group>
                {i.users.map((u) => (
                  <Avatar key={u.id} src={u.avatar} />
                ))}
              </Avatar.Group>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flex={1}
              px={3}
            >
              <Box flex="0 0 45%">
                <Button type="text" icon={<EditOutlined />} block>
                  <Text
                    fontSize={12}
                    display="inline-block"
                    ml={1}
                    fontWeight={600}
                  >
                    Manage
                  </Text>
                </Button>
              </Box>
              <Box flex="0 0 45%">
                <Button type="text" icon={<ExportOutlined />} block>
                  <Text
                    fontSize={12}
                    display="inline-block"
                    ml={1}
                    fontWeight={600}
                  >
                    Visit
                  </Text>
                </Button>
              </Box>
            </Box>
          </Box>
        </Col>
      ))}
    </Row>
  );
}

export default GridView;
