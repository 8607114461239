import { configureStore } from '@reduxjs/toolkit';
import api, { createAuthenticatedAPI } from './client';
import rootReducer from './reducers';

// @ts-ignore:
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const store = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            api,
            createAuthenticatedAPI,
          },
        },
      }),
    devTools: process.env.NODE_ENV !== 'production',
  });
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const typeStore = store();

export type RootState = ReturnType<typeof typeStore.getState>;

export type AppDispatch = typeof typeStore.dispatch;

export type ThunkApiFields = {
  dispatch: AppDispatch;
  state: RootState;
  rejectValue: string;
  rejectWithValue: (err: string) => void;
  extra: {
    api: any;
    createAuthenticatedAPI: any;
  };
};

export default store;
