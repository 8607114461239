import styled from 'styled-components';

export const RegisterPage = styled.div`
  padding-top: 80px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  min-height: calc(100vh - 80px);
  > section {
    flex: 0 0 500px;
    background: #ffffff;
  }
  > aside {
    flex: 1;
    background: #f6f6f6;
  }
`;
