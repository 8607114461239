import React from 'react';
import Box from 'components/Box';
import { Steps } from 'antd';
import Text from 'components/Text';

const { Step } = Steps;

function Header({ step }) {
  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      py={4}
      borderBottom="1px solid"
      borderBottomColor="gray"
    >
      <Box width={500}>
        <Steps progressDot current={step}>
          <Step title={<Text fontSize={12}>Customer Details</Text>} />
          <Step title={<Text fontSize={12}>Add Devices</Text>} />
          <Step title={<Text fontSize={12}>Invite People</Text>} />
        </Steps>
      </Box>
    </Box>
  );
}

export default Header;
