import { ThemeConfig } from 'antd/es/config-provider/context';

const theme: ThemeConfig | undefined = {
  token: {
    colorPrimary: '#5466E0',
    fontFamily: 'Poppins',
  },
  components: {
    Button: {
      borderRadiusLG: 0,
      borderRadius: 0,
    },
    Input: {
      borderRadiusLG: 0,
      borderRadius: 0,
    },
    InputNumber: {
      borderRadiusLG: 0,
      borderRadius: 0,
    },
    Select: {
      borderRadiusLG: 0,
      borderRadius: 0,
    },
    DatePicker: {
      borderRadiusLG: 0,
      borderRadius: 0,
    },
  },
};

export default theme;
