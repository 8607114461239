import styled from 'styled-components';

export const RegisterForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  > section {
    max-width: 345px;
    width: 100%;
    padding: 20px;
    h5 {
      font-size: 18px;
      text-align: center;
      margin-bottom: 30px;
    }
    strong {
      font-size: 12px;
      margin-bottom: 5px;
      display: block;
    }
  }
  footer {
    padding: 30px 0;

    a {
      display: inline-block;
      margin: 0 10px;
    }
  }
`;
