import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Input } from 'antd';
import HelpNav from 'features/HelpNav';
import Box from 'components/Box';
import Text from 'components/Text';
import HelpGettingStarted from 'features/HelpGettingStarted';
import HelpKnowledgeBased from 'features/HelpKnowledgeBased';
import HelpContactSupport from 'features/HelpContactSupport';

function HelpPage() {
  return (
    <React.Fragment>
      <Box py={75} px={4} textAlign="center" backgroundColor="#CDE0FB">
        <Text as="h3" fontSize={23} fontWeight={600}>
          Hello, what can we do for you?
        </Text>
        <Text fontSize={14}>Search our help center quick answer</Text>
        <Box maxWidth={370} m="20px auto 0">
          <Input.Search
            placeholder="Start typing your search..."
            size="large"
          />
        </Box>
      </Box>
      <Box py={70} px={4}>
        <Switch>
          <Route exact path="/app/help">
            <HelpNav />
          </Route>
          <Route exact path="/app/help/getting-started">
            <HelpGettingStarted />
          </Route>
          <Route exact path="/app/help/knowledge-based">
            <HelpKnowledgeBased />
          </Route>
          <Route exact path="/app/help/contact-and-support">
            <HelpContactSupport />
          </Route>
          <Redirect to="/app" />
        </Switch>
      </Box>
    </React.Fragment>
  );
}

export default HelpPage;
