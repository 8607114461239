import React, { useState } from 'react';
import { Form, Radio, Input, Select, Space, Button } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

function Step1() {
  const [customerType, setCustomerType] = useState(0);
  const history = useHistory();

  const handleCustomerType = (e) => {
    setCustomerType(e.target.value);
  };

  const handleFinish = () => {
    history.push('/app/customers/new/step-2');
  };

  return (
    <Box
      maxWidth={670}
      width="100%"
      backgroundColor="white"
      border="1px solid"
      borderColor="gray"
      m="auto"
      py={35}
      px={100}
    >
      <Text as="h4" fontWeight={600} fontSize={18} mb={2}>
        Customer Details
      </Text>
      <Text fontSize={13} mb={6}>
        Information about your customer whether is it an individual or company
        and select their desired plan.
      </Text>
      <Box mb={4}>
        <Text fontSize={12} mb={2} fontWeight={600}>
          Customer Type
        </Text>
        <Radio.Group onChange={handleCustomerType} value={customerType}>
          <Radio value={1}>Individual</Radio>
          <Radio value={2}>Company</Radio>
        </Radio.Group>
      </Box>
      <Form layout="vertical" onFinish={handleFinish}>
        <Form.Item
          label={
            <Text fontSize={12} fontWeight={600}>
              Name
            </Text>
          }
          name="name"
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label={
            <Text fontSize={12} fontWeight={600}>
              Email Address
            </Text>
          }
          name="email"
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label={
            <Text fontSize={12} fontWeight={600}>
              Phone or Mobile
            </Text>
          }
          name="phone"
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label={
            <Text fontSize={12} fontWeight={600}>
              Choose Plan
            </Text>
          }
          name="plan"
        >
          <Select size="large">
            <Option value="copper">Copper</Option>
            <Option value="bronze">Bronze</Option>
            <Option value="silver">Silver</Option>
            <Option value="gold">Gold</Option>
            <Option value="platinum">Platinum</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <Text fontSize={12} fontWeight={600}>
              Notes
            </Text>
          }
          name="notes"
        >
          <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} size="large" />
        </Form.Item>
        <Box display="flex" justifyContent="flex-end">
          <Space>
            <Button type="default" size="large">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" size="large">
              Next
            </Button>
          </Space>
        </Box>
      </Form>
    </Box>
  );
}

export default Step1;
