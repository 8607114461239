import React from 'react';
import { message } from 'antd';

import { useHistory } from 'react-router-dom';
import Auth0, { handleClearTokens, handleLogout } from 'common/auth0';
import localStorage from 'localStorage';
import { useDispatch } from 'react-redux';
import { setRole } from 'features/Users/slice';
import { useUpdateEffect } from 'react-use';
import * as S from './styles';

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useUpdateEffect(() => {
    Auth0.parseHash({ hash: history.location.hash }, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        handleClearTokens();
        dispatch(
          setRole(authResult?.idTokenPayload['https://imageengine.ai'].roles),
        );
        localStorage.setItem(
          'roles',
          authResult?.idTokenPayload['https://imageengine.ai'].roles,
        );
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        history.push('/app/devices');
      } else if (err) {
        message.error(err.errorDescription || 'Unable to Validate!');
        handleLogout();
        history.push('/');
      }
    });
  }, [history]);

  return <S.Authenticate />;
};
