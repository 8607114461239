import React from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';

import {
  Space,
  Typography,
  Row,
  Divider,
  Button,
  Select,
  Col,
  TimePicker,
  Form,
  Spin,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { DatabaseOutlined, PlusOutlined } from '@ant-design/icons';
import useDeviceList from 'features/DeviceList/hooks';

import {
  createUserDeviceSchedule,
  getUserDeviceSchedules,
  patchUserDeviceSchedule,
  deleteUserDeviceSchedule,
  selectPendingGetDeviceSchedules,
  selectPendingCreateDeviceSchedules,
  selectPendingPatchDeviceSchedules,
  selectPendingDeleteDeviceSchedules,
  selectNotificationSchedule,
} from 'features/Users/slice';

import Box from 'components/Box';
import { openSuccessNotification } from 'common/helpers';

const { Title } = Typography;
const { Option } = Select;

function Notifications() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const notificationSchedule = useSelector(selectNotificationSchedule);
  const pendingCreateDeviceSchedule = useSelector(
    selectPendingCreateDeviceSchedules,
  );
  const pendingGetDeviceSchedule = useSelector(selectPendingGetDeviceSchedules);
  const pendingPatchDeviceSchedule = useSelector(
    selectPendingPatchDeviceSchedules,
  );
  const pendingDeleteDeviceSchedule = useSelector(
    selectPendingDeleteDeviceSchedules,
  );
  const [showCreate, setShowCreate] = React.useState(false);
  const [selectedSchedule, setSelectedSchedule] = React.useState('');
  const [scheduleObjectMapping, setScheduleObjectMapping] = React.useState({});

  const notificationGateway = ['App', 'Email'];

  const notificationDay = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const notificationDayObject = {
    Monday: 'MON',
    Tuesday: 'TUE',
    Wednesday: 'WED',
    Thursday: 'THU',
    Friday: 'FRI',
    Saturday: 'SAT',
    Sunday: 'SUN',
  };

  const { allDevices } = useDeviceList();

  const handleCrateNewSchedule = () => {
    form.setFieldsValue({
      deviceId: undefined,
      days: [],
      time: [],
      notificationType: [],
    });
    setShowCreate(true);
  };

  const handleAddSchedule = () => {
    form.validateFields().then(async (values) => {
      const { deviceId, days, time, notificationType } = values;
      const schedule = {
        deviceId,
        isMonday: days.includes('Monday'),
        isTuesday: days.includes('Tuesday'),
        isWednesday: days.includes('Wednesday'),
        isThursday: days.includes('Thursday'),
        isFriday: days.includes('Friday'),
        isSaturday: days.includes('Saturday'),
        isSunday: days.includes('Sunday'),

        startTime: dayjs(time[0]).format('HH:mm'),
        endTime: dayjs(time[1]).format('HH:mm'),

        isNotifyByEmail: notificationType.includes('Email'),
        isNotifyBySMS: notificationType.includes('SMS'),
        isNotifyByApp: notificationType.includes('App'),
      };

      message.loading('Adding Notification Schedule...');
      await dispatch(createUserDeviceSchedule({ deviceId, schedule }));

      message.destroy();
      openSuccessNotification({
        message: 'Notification Schedule Added',
        description: `New Schedule is added for device ${deviceId}`,
      });
      setShowCreate(false);
    });
  };

  const handleUpdateSchedule = async (schedule) => {
    const { deviceId, id } = schedule;
    setSelectedSchedule(id);
    await dispatch(
      patchUserDeviceSchedule({
        deviceId,
        schedule: scheduleObjectMapping[id],
      }),
    );
    setSelectedSchedule('');
  };

  const handleDeleteSchedule = async (schedule) => {
    const { deviceId, id } = schedule;
    setSelectedSchedule(id);
    await dispatch(deleteUserDeviceSchedule({ deviceId, schedule }));
    setSelectedSchedule('');
  };

  // UPDATE

  const handleUpdateScheduleObject = (scheduleId, updatedSchedule) => {
    const newObject = { ...scheduleObjectMapping };
    newObject[scheduleId] = updatedSchedule;
    setScheduleObjectMapping(newObject);
  };

  const handleUpdateSelectedDevice = (scheduleId, deviceId) => {
    const updatedSchedule = { ...scheduleObjectMapping[scheduleId], deviceId };
    handleUpdateScheduleObject(scheduleId, updatedSchedule);
  };

  const handleUpdateSelectedDays = (scheduleId, days) => {
    const updatedSchedule = {
      ...scheduleObjectMapping[scheduleId],
      isMonday: days.includes('Monday'),
      isTuesday: days.includes('Tuesday'),
      isWednesday: days.includes('Wednesday'),
      isThursday: days.includes('Thursday'),
      isFriday: days.includes('Friday'),
      isSaturday: days.includes('Saturday'),
      isSunday: days.includes('Sunday'),
    };
    handleUpdateScheduleObject(scheduleId, updatedSchedule);
  };

  const handleUpdateSelectedTimeRange = (scheduleId, time) => {
    const updatedSchedule = {
      ...scheduleObjectMapping[scheduleId],
      startTime: dayjs(time[0]).format('HH:mm'),
      endTime: dayjs(time[1]).format('HH:mm'),
    };
    handleUpdateScheduleObject(scheduleId, updatedSchedule);
  };

  const handleUpdateSelectedNotificationGateway = (scheduleId, gateway) => {
    const updatedSchedule = {
      ...scheduleObjectMapping[scheduleId],
      isNotifyByEmail: gateway.includes('Email'),
      isNotifyBySMS: gateway.includes('SMS'),
      isNotifyByApp: gateway.includes('App'),
    };
    handleUpdateScheduleObject(scheduleId, updatedSchedule);
  };

  useEffectOnce(() => {
    dispatch(getUserDeviceSchedules());
  });

  useUpdateEffect(() => {
    const newObject = {};
    notificationSchedule.map((schedule) => {
      newObject[schedule.id] = schedule;
      return true;
    });
    setScheduleObjectMapping(newObject);
  }, [notificationSchedule]);

  return (
    <Box p={{ _: 20, sm: 30 }}>
      <Space style={{ margin: '0 0 20px 0' }}>
        <DatabaseOutlined style={{ fontSize: '21px' }} />
        <Title level={4} style={{ margin: '0' }}>
          Notifications
        </Title>
      </Space>
      <Title level={5} style={{ marginBottom: '20px' }}>
        Where You Receive Notifications
      </Title>
      <div style={{ marginBottom: '20px' }}>
        <Button
          type="primary"
          size="large"
          onClick={handleCrateNewSchedule}
          icon={<PlusOutlined />}
        >
          Create schedule
        </Button>
      </div>
      {showCreate && (
        <Form form={form} layout="vertical">
          <Row gutter={20}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 19 }}>
              <Row gutter={20}>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item name="deviceId">
                    <Select size="large" placeholder="Select Camera">
                      <Option value="">All Camera</Option>)
                      {allDevices.map((device) => (
                        <Option value={device.id} key={device.id}>
                          {`[${device.deviceGroupName}] - ${device.name} - ${device.description}`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item name="days">
                    <Select
                      mode="multiple"
                      size="large"
                      placeholder="Select days"
                    >
                      {notificationDay.map((day) => (
                        <Option value={day} label={day} key={day}>
                          {notificationDayObject[day]}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item name="time">
                    <TimePicker.RangePicker
                      format="hh:mm A"
                      size="large"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item name="notificationType">
                    <Select
                      mode="multiple"
                      size="large"
                      placeholder="Select Notification Method"
                    >
                      {notificationGateway.map((gateway) => (
                        <Option value={gateway} label={gateway} key={gateway}>
                          {gateway}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 5 }}>
              <Row gutter={[20, 16]}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                >
                  <Button
                    loading={pendingCreateDeviceSchedule}
                    onClick={handleAddSchedule}
                    size="large"
                    block
                    type="primary"
                    ghost
                  >
                    Save
                  </Button>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                >
                  <Button
                    onClick={() => setShowCreate(false)}
                    size="large"
                    block
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}

      {notificationSchedule && notificationSchedule.length > 0 && (
        <Spin spinning={pendingGetDeviceSchedule}>
          {notificationSchedule.map((schedule) => {
            const daysList = [];
            const notificationType = [];

            if (schedule.isMonday) {
              daysList.push('Monday');
            }

            if (schedule.isTuesday) {
              daysList.push('Tuesday');
            }

            if (schedule.isWednesday) {
              daysList.push('Wednesday');
            }

            if (schedule.isThursday) {
              daysList.push('Thursday');
            }

            if (schedule.isFriday) {
              daysList.push('Friday');
            }

            if (schedule.isSaturday) {
              daysList.push('Saturday');
            }

            if (schedule.isSunday) {
              daysList.push('Sunday');
            }

            if (schedule.isNotifyByApp) {
              notificationType.push('App');
            }

            if (schedule.isNotifyByEmail) {
              notificationType.push('Email');
            }

            if (schedule.isNotifyBySms) {
              notificationType.push('SMS');
            }

            const timeRange = [
              dayjs(
                dayjs(schedule.startTime, 'HH:mm').format('hh:mm A'),
                'hh:mm A',
              ),
              dayjs(
                dayjs(schedule.endTime, 'HH:mm').format('hh:mm A'),
                'hh:mm A',
              ),
            ];

            return (
              <React.Fragment key={schedule.id}>
                <Divider />
                <Row gutter={[20, 20]}>
                  <Col xs={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 19 }}>
                    <Row gutter={[20, 16]}>
                      <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <Select
                          onChange={(val) =>
                            handleUpdateSelectedDevice(schedule.id, val)
                          }
                          defaultValue={schedule.deviceId}
                          placeholder="Select Camera"
                          size="large"
                          style={{ width: '100%' }}
                        >
                          <Option value={null}>All Camera</Option>)
                          {allDevices.map((device) => (
                            <Option value={device.id} key={device.id}>
                              {`[${device.deviceGroupName}] - ${device.name} - ${device.description}`}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <Select
                          mode="multiple"
                          onChange={(val) =>
                            handleUpdateSelectedDays(schedule.id, val)
                          }
                          defaultValue={daysList}
                          size="large"
                          style={{ width: '100%' }}
                        >
                          {notificationDay.map((day) => (
                            <Option value={day} label={day} key={day}>
                              {notificationDayObject[day]}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <TimePicker.RangePicker
                          onChange={(val) =>
                            handleUpdateSelectedTimeRange(schedule.id, val)
                          }
                          defaultValue={timeRange}
                          format="hh:mm A"
                          style={{ width: '100%' }}
                          size="large"
                        />
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <Select
                          onChange={(val) =>
                            handleUpdateSelectedNotificationGateway(
                              schedule.id,
                              val,
                            )
                          }
                          defaultValue={notificationType}
                          mode="multiple"
                          style={{ width: '100%' }}
                          size="large"
                        >
                          {notificationGateway.map((gateway) => (
                            <Option
                              value={gateway}
                              label={gateway}
                              key={gateway}
                            >
                              {gateway}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 5 }}>
                    <Row gutter={[20, 16]}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        lg={{ span: 24 }}
                        xl={{ span: 24 }}
                      >
                        <Button
                          loading={
                            selectedSchedule === schedule.id &&
                            pendingPatchDeviceSchedule
                          }
                          onClick={() => handleUpdateSchedule(schedule)}
                          size="large"
                          block
                        >
                          Save Changes
                        </Button>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        lg={{ span: 24 }}
                        xl={{ span: 24 }}
                      >
                        <Button
                          loading={
                            selectedSchedule === schedule.id &&
                            pendingDeleteDeviceSchedule
                          }
                          onClick={() => handleDeleteSchedule(schedule)}
                          size="large"
                          block
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </React.Fragment>
            );
          })}
        </Spin>
      )}
    </Box>
  );
}

export default Notifications;
