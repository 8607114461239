import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { Badge, Button, Space, Avatar, Image, Popover } from 'antd';
import {
  BellOutlined,
  MenuUnfoldOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import Box from 'components/Box';

import {
  getUserNotifications,
  selectPendingGetNotifications,
  selectNotifications,
  selectPendingUpdateAvatar,
} from 'features/Users/slice';

import logoImageEngine from 'images/image-engine-logo.png';

import { useSession } from 'service/session/hooks';

import { PopoverNotification, PopoverProfile } from './Popovers';

import * as S from './styles';

function TopNav({ setSideNav }) {
  const { user, roles } = useSession();

  const pendingUpdateAvatar = useSelector(selectPendingUpdateAvatar);
  const dispatch = useDispatch();

  const notifications = useSelector(selectNotifications);
  const pendingGetNotifications = useSelector(selectPendingGetNotifications);

  useEffectOnce(() => {
    dispatch(getUserNotifications());
  });

  return (
    <S.TopNav>
      <section>
        <Box display={{ _: 'flex', md: 'none' }} alignItems="center" mr={3}>
          <MenuUnfoldOutlined
            onClick={() => setSideNav(true)}
            style={{ fontSize: '24px' }}
          />
          <img
            src={logoImageEngine}
            alt="logo"
            className="logo"
            style={{ marginLeft: '20px' }}
          />
        </Box>
      </section>
      <Space>
        <Box display={{ _: 'none', md: 'block' }}>
          <Popover
            content={<PopoverNotification list={notifications} />}
            title="Notifications"
            placement="bottomRight"
          >
            <Badge size="small" count={notifications.length}>
              <Button
                type="default"
                shape="circle"
                icon={
                  pendingGetNotifications ? (
                    <LoadingOutlined />
                  ) : (
                    <BellOutlined />
                  )
                }
              />
            </Badge>
          </Popover>
        </Box>
        <Popover content={<PopoverProfile />} placement="bottomRight">
          <S.ProfileBtn>
            <Box display={{ _: 'none', md: 'block' }}>
              <p className="username">
                {user?.profile?.name}
                <br />
                {roles.includes('Admin') && <b>ADMIN</b>}
                {roles.includes('Reseller') && <b>RESELLER</b>}
              </p>
            </Box>
            <Avatar
              src={
                <Image
                  src={pendingUpdateAvatar ? '' : user?.profile?.avatar}
                  preview={false}
                />
              }
            />
          </S.ProfileBtn>
        </Popover>
      </Space>
    </S.TopNav>
  );
}

export default TopNav;
