import React from 'react';
import { Space, Typography, Tabs, Table, Button, Input } from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';
import { useEffectOnce } from 'react-use';

import { StyledSavedPlateNumbers } from './styles';
import useSavedPlateNumbers from './hooks';

const { Title } = Typography;
const { TabPane } = Tabs;

function SavedPlateNumbers() {
  const [plate, setPlate] = React.useState('');
  const [description, setDescription] = React.useState('');

  const {
    whiteListedPlates,
    blackListedPlates,
    getSavedPlates,
    createSavedPlates,
    deleteSavedPlates,
    pendingDelete,
    pendingGet,
    pendingCreate,
  } = useSavedPlateNumbers();

  const handleCreate = (type) => {
    createSavedPlates({ plate, description, type, deviceId: '' });
  };

  const handleResetForm = () => {
    setPlate('');
    setDescription('');
  };

  useEffectOnce(() => {
    getSavedPlates();
  });

  const columns = [
    {
      title: 'Plate',
      dataIndex: 'plate',
      key: 'plate',
      width: '15%',
      render: (data) => <span>{data.toUpperCase()}</span>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'age',
    },
    // {
    //   title: 'Device',
    //   dataIndex: 'deviceId',
    //   key: 'deviceId',
    // },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              deleteSavedPlates(record.id);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <StyledSavedPlateNumbers p={{ _: 20, sm: 30 }}>
      <Space style={{ margin: '0 0 20px 0' }}>
        <DatabaseOutlined style={{ fontSize: '21px' }} />
        <Title level={4} style={{ margin: '0' }}>
          Saved Plate Numbers
        </Title>
      </Space>
      <Tabs onChange={handleResetForm}>
        <TabPane tab="Whitelist" key="whitelist">
          <div className="plate-numbers-form">
            <Input
              value={plate}
              onChange={({ target: { value } }) => {
                setPlate(value);
              }}
              placeholder="Plate Number"
              style={{ width: '130px' }}
            />

            <Input
              value={description}
              onChange={({ target: { value } }) => {
                setDescription(value);
              }}
              placeholder="Description"
              style={{ width: '350px' }}
            />
            <Button type="primary" onClick={() => handleCreate('White List')}>
              Add
            </Button>
          </div>
          <Table
            loading={pendingGet || pendingCreate || pendingDelete}
            columns={columns}
            dataSource={whiteListedPlates}
          />
        </TabPane>
        <TabPane tab="Blacklist" key="blacklist">
          <div className="plate-numbers-form">
            <Input
              value={plate}
              onChange={({ target: { value } }) => {
                setPlate(value);
              }}
              placeholder="Plate Number"
              style={{ width: '130px' }}
            />
            <Input
              value={description}
              onChange={({ target: { value } }) => {
                setDescription(value);
              }}
              placeholder="Description"
              style={{ width: '350px' }}
            />
            <Button type="primary" onClick={() => handleCreate('Black List')}>
              Add
            </Button>
          </div>
          <Table
            loading={pendingGet || pendingCreate || pendingDelete}
            columns={columns}
            dataSource={blackListedPlates}
          />
        </TabPane>
      </Tabs>
    </StyledSavedPlateNumbers>
  );
}

export default SavedPlateNumbers;
