import React from 'react';
import { Typography, Space } from 'antd';
import { NavLink } from 'react-router-dom';
import Box from 'components/Box';

import { DollarOutlined, CreditCardOutlined } from '@ant-design/icons';
import { useSession } from 'service/session/hooks';
import { NAV_ITEMS } from './constants';
import * as S from './styles';

const { Title } = Typography;

function SettingsNav() {
  const { roles: userRole } = useSession();
  return (
    <div>
      <Box display={{ _: 'none', lg: 'block' }}>
        <S.DesktopNav>
          <Title level={4}>Settings</Title>
          <ul>
            {NAV_ITEMS.map((i) => (
              <li key={i.name}>
                <NavLink
                  to={i.route}
                  className="nav-link"
                  activeClassName="active"
                >
                  <Space align="center">
                    {i.icon}
                    <strong>{i.name}</strong>
                  </Space>
                </NavLink>
              </li>
            ))}
            {userRole.includes('Reseller') && (
              <React.Fragment>
                <li key="Reseller">
                  <NavLink
                    to="/app/settings/reseller"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <Space align="center">
                      <DollarOutlined style={{ fontSize: '21px' }} />
                      <strong>Reseller</strong>
                    </Space>
                  </NavLink>
                </li>
                <li key="PaymentMethods">
                  <NavLink
                    to="/app/settings/payment-methods"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <Space align="center">
                      <CreditCardOutlined style={{ fontSize: '21px' }} />
                      <strong>Payment Methods</strong>
                    </Space>
                  </NavLink>
                </li>
              </React.Fragment>
            )}
          </ul>
        </S.DesktopNav>
      </Box>
      <Box display={{ _: 'block', lg: 'none' }}>
        <S.MobileNav>
          <ul>
            {NAV_ITEMS.map((i) => (
              <li key={i.name}>
                <NavLink
                  to={i.route}
                  className="nav-link"
                  activeClassName="active"
                >
                  <Space align="center">{i.icon}</Space>
                </NavLink>
              </li>
            ))}
            {userRole.includes('Reseller') && (
              <React.Fragment>
                <li key="Reseller">
                  <NavLink
                    to="/app/settings/reseller"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <Space align="center">
                      <DollarOutlined style={{ fontSize: '21px' }} />
                    </Space>
                  </NavLink>
                </li>
                <li key="PaymentMethods">
                  <NavLink
                    to="/app/settings/payment-methods"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <Space align="center">
                      <CreditCardOutlined style={{ fontSize: '21px' }} />
                    </Space>
                  </NavLink>
                </li>
              </React.Fragment>
            )}
          </ul>
        </S.MobileNav>
      </Box>
    </div>
  );
}

export default SettingsNav;
