export default {
  primary: '#F54242',
  secondary: '#5466E0',
  black: '#212121',
  white: '#ffffff',
  white_1: '#f9f9fc',
  gray: '#e3e9ee',
  gray_1: '#fafafa',
  gray_2: '#9A9A9A',
  gray_3: '#F0F0F0',
  gray_4: '#ECECEE',
  gray_5: '#797979',
  green: '#1AB529',
  gold: '#F0B92A',
  silver: '#BABABA',
  copper: '#CA834F',
  bronze: '#A07A40',
};
