import React, { useState } from 'react';
import { Table, Button, Modal, Space, Tag, Dropdown, Menu } from 'antd';
import { SettingOutlined, PlusOutlined } from '@ant-design/icons';
import Box from 'components/Box';
import { useHistory } from 'react-router-dom';
import { useSession } from 'service/session/hooks';

import ResellerAdd from 'features/ResellerAdd';
import useAddDevice from 'features/DeviceAddDrawer/hooks';

function ListView({ list }) {
  const [showResellerAdd, setShowResellerAdd] = useState(false);
  const { roles: userRole } = useSession();
  const history = useHistory();

  const { showModal: showResellerAddSubscription, setResellerCustomer } =
    useAddDevice();

  const handleAddCustomerSubscription = (customerId) => {
    showResellerAddSubscription(true);
    setResellerCustomer(customerId);
  };

  const AdminColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (record, data) => {
        return (
          <React.Fragment>
            {record}
            &nbsp;
            {data.isReseller && <Tag color="blue">Reseller</Tag>}
          </React.Fragment>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Reseller Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Reseller',
      dataIndex: 'resellerId',
      key: 'resellerId',
      render: (record, data) => {
        return <React.Fragment>{data.resellerDetails?.name}</React.Fragment>;
      },
    },
    {
      title: '',
      align: 'center',
      key: 'action',
      width: 50,
      render: () => (
        <Space size="middle">
          <Button type="text" icon={<SettingOutlined />} block />
        </Space>
      ),
    },
  ];

  const ResellerColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (record, data) => {
        return (
          <React.Fragment>
            {record}
            &nbsp;
            {data.isReseller && <Tag color="blue">Reseller</Tag>}
          </React.Fragment>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '',
      align: 'center',
      key: 'action',
      width: 50,
      render: (record, data) => (
        <Space size="middle">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  onClick={() => handleAddCustomerSubscription(data.id)}
                >
                  Add Subscription
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={() =>
                    history.push(`/app/customers/${data.id}/devices`)
                  }
                >
                  Manage Device
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button
              style={{ padding: '0 6px' }}
              type="text"
              icon={<SettingOutlined />}
              block
            />
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="end" mb={4}>
        {/* <Button style={{ marginRight: '10px' }} type="primary" icon={<PlusOutlined />}>New Customer</Button> */}
        {userRole.includes('Admin') && (
          <Button
            style={{ marginRight: '10px' }}
            onClick={() => setShowResellerAdd(true)}
            type="primary"
            icon={<PlusOutlined />}
          >
            New Reseller
          </Button>
        )}
      </Box>
      <Table
        columns={userRole.includes('Admin') ? AdminColumns : ResellerColumns}
        dataSource={list}
      />
      <Modal
        open={showResellerAdd}
        closable
        onCancel={() => setShowResellerAdd(false)}
        footer={null}
      >
        <ResellerAdd onCreate={() => setShowResellerAdd(false)} />
      </Modal>
    </Box>
  );
}

export default ListView;
