import styled from 'styled-components';
import colors from 'styles/colors';

export const PageFilter = styled.div`
  width: 100%;
  height: 63px;
  border-bottom: 1px solid ${colors.gray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;

  h2 {
    font-size: 18px;
    font-weight: 600;
  }
`;
