import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { filter } from 'lodash';
import { selectAllProducts, productsGet } from './slice';

function useProductList() {
  const dispatch = useDispatch();
  const allProducts = useSelector(selectAllProducts);
  const [deviceProductsByCode, setDeviceProductsByCode] = useState({});
  const [deviceAddOnsByCode, setDeviceAddOnsByCode] = useState({});

  const fetchData = () => {
    dispatch(productsGet());
  };

  useEffectOnce(() => {
    fetchData();
  });

  useUpdateEffect(() => {
    setDeviceProductsByCode({
      ...filter(allProducts, (product) => product.type === 'Device').reduce(
        (result, item) => {
          result[item.id] = item;
          return result;
        },
        {},
      ),
    });

    setDeviceAddOnsByCode({
      ...filter(allProducts, (product) => product.type === 'Add On').reduce(
        (result, item) => {
          result[item.id] = item;
          return result;
        },
        {},
      ),
    });
  }, [allProducts]);

  return { allProducts, deviceProductsByCode, deviceAddOnsByCode };
}

export default useProductList;
