import React from 'react';
import { Space, Input } from 'antd';
import {
  FilterOutlined,
  SearchOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';
import Box from 'components/Box';
import Text from 'components/Text';
import { useDispatch } from 'react-redux';
import { setSearch } from 'features/CustomerDeviceList/slice';

function Filter({ view, setView }) {
  const dispatch = useDispatch();
  const onSearch = (value) => {
    dispatch(setSearch(value));
  };

  return (
    <Box
      width={{ _: '100%', md: 'calc(100% - 228px)' }}
      height={63}
      borderBottom="1px solid"
      borderColor="gray"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={25}
      backgroundColor="white"
      left={{ _: 0, md: 230 }}
      zIndex={10}
      position="fixed"
    >
      <Space>
        <Text fontSize={18} fontWeight={600}>
          Customers
        </Text>
      </Space>
      <Box display={{ _: 'none', sm: 'block' }}>
        <Space>
          <Input.Search placeholder="Search..." onSearch={onSearch} />
          <FilterOutlined style={{ fontSize: '21px' }} onClick={() => {}} />
          {view === 'grid' ? (
            <UnorderedListOutlined
              style={{ fontSize: '21px', cursor: 'pointer' }}
              onClick={() => setView('list')}
            />
          ) : (
            <AppstoreOutlined
              style={{ fontSize: '21px', cursor: 'pointer' }}
              onClick={() => setView('grid')}
            />
          )}
        </Space>
      </Box>
      <Box display={{ _: 'block', sm: 'none' }}>
        <SearchOutlined style={{ fontSize: '21px' }} onClick={() => {}} />
      </Box>
    </Box>
  );
}

export default Filter;
