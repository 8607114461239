import React, { ComponentType } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { LoadingOutlined } from '@ant-design/icons';

import { useAuth0 } from '@auth0/auth0-react';
import { loadStripe } from '@stripe/stripe-js';

import AuthenticatedPage from 'pages/AuthenticatedPage';
import RegisterPage from 'pages/RegisterPage';
import AuthPage from 'pages/AuthPage';

import ScrollToTop from 'components/ScrollToTop';

library.add(fab, fas);

type PrivateRouteType = {
  component: ComponentType;
} & RouteProps;

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteType) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="flex h-[calc(100vh)] w-full items-center justify-center pt-10 align-middle">
        <LoadingOutlined style={{ fontSize: '100px', color: '#acacac' }} />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) return <Component {...props} />;

        return <Redirect to="/" />;
      }}
    />
  );
};

const App: React.FC = () => {
  // @ts-ignore
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    // @ts-ignore
    <Elements stripe={stripePromise}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/auth">
            <AuthPage />
          </Route>
          <Route path="/register">
            <RegisterPage />
          </Route>
          <Route path="/login">
            <RegisterPage />
          </Route>
          <Route exact path="/">
            <RegisterPage />
          </Route>

          <PrivateRoute path="/app" component={AuthenticatedPage} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </Elements>
  );
};

export default App;
