import React from 'react';
import DeviceDetail from 'features/DeviceDetail';

import * as S from './styles';

function DeviceDetailPage() {
  return (
    <S.DeviceDetailPage>
      <DeviceDetail />
    </S.DeviceDetailPage>
  );
}

export default DeviceDetailPage;
