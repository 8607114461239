import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { openErrorNotification } from 'common/helpers';
import dayjs from 'dayjs';
import qs from 'qs';
import localStorage from 'localStorage';
import { message } from 'antd';

export const getUserMe = createAsyncThunk(
  'user/getMe',
  async (payload, { extra: { createAuthenticatedAPI }, rejectWithValue }) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.post('/user/me', payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateUserAvatar = createAsyncThunk(
  'user/updateAvatar',
  async ({ file }, { extra: { createAuthenticatedAPI }, rejectWithValue }) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.post('/user/avatar', { uri: file.base64 });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getUserDeviceFavorites = createAsyncThunk(
  'user/getDeviceFavorites',
  async () =>
    // payload,
    // { extra: { createAuthenticatedAPI }, rejectWithValue },
    {
      // TODO
      return [];
      // const api = createAuthenticatedAPI();
      // try {
      //   const response = await api.get('/user/favorite/devices', payload);
      //   return response;
      // } catch (err) {
      //   if (!err.response) {
      //     throw err;
      //   }
      //   return rejectWithValue(err.response.data);
      // }
    },
);

export const getUserGalleries = createAsyncThunk(
  'user/getGalleries',
  async (
    { deviceId, from, to, page },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      const response = await api.get('/user/galleries', {
        params: { deviceId, page, from, to },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      });

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getUserNotifications = createAsyncThunk(
  'user/notifications',
  async (payload, { extra: { createAuthenticatedAPI }, rejectWithValue }) => {
    const api = createAuthenticatedAPI();
    try {
      const data = await api.get('/notification/list?isNew=1');
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteUserNotification = createAsyncThunk(
  'user/notifications',
  async (payload, { extra: { createAuthenticatedAPI } }) => {
    const api = createAuthenticatedAPI();

    api.delete(`/notification/list/${payload.notificationId}`).catch();
  },
);

export const getUsers = createAsyncThunk(
  'users/get',
  async (payload, { extra: { createAuthenticatedAPI }, rejectWithValue }) => {
    const api = createAuthenticatedAPI();
    try {
      const { data } = await api.get(`/user/users?search=${payload}`);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getUserDeviceSchedules = createAsyncThunk(
  'users-notification-schedules/get',
  async (payload, { extra: { createAuthenticatedAPI }, rejectWithValue }) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.get('/user/notification-schedules');
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const createUserDeviceSchedule = createAsyncThunk(
  'users-notification-schedules/create',
  async (
    { schedule },
    { extra: { createAuthenticatedAPI }, rejectWithValue, dispatch },
  ) => {
    const api = createAuthenticatedAPI();

    const parsedSchedule = { ...schedule };
    const newStartTime = new Date(
      +dayjs(schedule.startTime, 'HH:mm').format('x'),
    ).toISOString();
    parsedSchedule.startTime = schedule.startTime;
    parsedSchedule.startAt = newStartTime;

    const newEndTime = new Date(
      +dayjs(schedule.endTime, 'HH:mm').format('x'),
    ).toISOString();
    parsedSchedule.endTime = schedule.endTime;
    parsedSchedule.endAt = newEndTime;

    try {
      await api.post('/user/notification-schedules', parsedSchedule);
      dispatch(getUserDeviceSchedules());
      return true;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const patchUserDeviceSchedule = createAsyncThunk(
  'users-notification-schedules/patch',
  async (
    { schedule },
    { extra: { createAuthenticatedAPI }, rejectWithValue, dispatch },
  ) => {
    const api = createAuthenticatedAPI();

    try {
      const parsedSchedule = { ...schedule };
      const newStartTime = new Date(
        +dayjs(schedule.startTime, 'HH:mm').format('x'),
      ).toISOString();
      parsedSchedule.startAt = newStartTime;

      const newEndTime = new Date(
        +dayjs(schedule.endTime, 'HH:mm').format('x'),
      ).toISOString();
      parsedSchedule.endAt = newEndTime;

      await api.patch(
        `/user/notification-schedules/${parsedSchedule.id}`,
        parsedSchedule,
      );
      dispatch(getUserDeviceSchedules());
      return true;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteUserDeviceSchedule = createAsyncThunk(
  'users-notification-schedules/delete',
  async (
    { schedule },
    { extra: { createAuthenticatedAPI }, rejectWithValue, dispatch },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      await api.delete(`/user/notification-schedules/${schedule.id}`);
      dispatch(getUserDeviceSchedules());
      return true;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const patchUser = createAsyncThunk(
  'user/patch',
  async (
    payload,
    { extra: { createAuthenticatedAPI }, rejectWithValue, dispatch },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      const { data } = await api.patch('/user/me', payload);
      dispatch(getUserMe());
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const contactUser = createAsyncThunk(
  'user/contact',
  async (payload, { extra: { createAuthenticatedAPI }, rejectWithValue }) => {
    const api = createAuthenticatedAPI();
    try {
      const response = await api.post('/forms', payload);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  list: [],
  deviceFavorites: [],
  notifications: [],
  notificationSchedules: [],
  me: {},
  role: [],
  totalGallery: 0,
  pendingGetDeviceSchedules: false,
  pendingGetNotifications: false,
  pendingGetGalleries: false,
  pendingCreateDeviceSchedules: false,
  pendingPatchDeviceSchedules: false,
  pendingDeleteDeviceSchedules: false,
  pendingUpdateAvatar: false,
  pendingGet: false,
  pendingMe: false,
  pendingPatch: false,
  pendingContactForm: false,
};

export const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(contactUser.fulfilled, (state) => {
        message.success('Message sent successfully');
        state.pendingContactForm = false;
      })
      .addCase(contactUser.pending, (state) => {
        state.pendingContactForm = true;
      })
      .addCase(contactUser.rejected, (state) => {
        message.error('Failed to send message.');
        state.pendingContactForm = false;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.pendingGet = false;
        state.list = payload;
      })
      .addCase(getUsers.pending, (state) => {
        state.pendingGet = true;
      })
      .addCase(getUsers.rejected, (state) => {
        openErrorNotification('Failed to get.');
        state.pendingGet = false;
      })
      .addCase(updateUserAvatar.fulfilled, (state) => {
        state.pendingUpdateAvatar = false;
      })
      .addCase(updateUserAvatar.pending, (state) => {
        state.pendingUpdateAvatar = true;
      })
      .addCase(updateUserAvatar.rejected, (state) => {
        openErrorNotification('Failed to update avatar.');
        state.pendingUpdateAvatar = false;
      })
      .addCase(getUserNotifications.fulfilled, (state, { payload }) => {
        state.pendingGetNotifications = false;
        state.notifications = payload;
      })
      .addCase(getUserNotifications.pending, (state) => {
        state.pendingGetNotifications = true;
      })
      .addCase(getUserNotifications.rejected, (state) => {
        openErrorNotification('Failed to get.');
        state.pendingGetNotifications = false;
      })
      .addCase(getUserDeviceFavorites.fulfilled, (state, { payload }) => {
        state.deviceFavorites = payload;
      })
      .addCase(patchUser.fulfilled, (state, { payload }) => {
        state.pendingPatch = false;
        state.list = payload;
      })
      .addCase(patchUser.pending, (state) => {
        state.pendingPatch = true;
      })
      .addCase(patchUser.rejected, (state) => {
        openErrorNotification('Failed to update.');
        state.pendingPatch = false;
      })
      .addCase(getUserMe.fulfilled, (state, { payload }) => {
        state.pendingMe = false;
        state.me = payload;
      })
      .addCase(getUserMe.pending, (state) => {
        state.pendingMe = true;
      })
      .addCase(getUserMe.rejected, (state) => {
        openErrorNotification('Failed to get User Details.');
        state.pendingMe = false;
      })
      .addCase(getUserGalleries.fulfilled, (state, { payload }) => {
        const { data, total } = payload;
        state.pendingGetGalleries = false;
        state.gallery = data;
        state.totalGallery = total;
      })
      .addCase(getUserGalleries.pending, (state) => {
        state.pendingGetGalleries = true;
      })
      .addCase(getUserGalleries.rejected, (state) => {
        openErrorNotification('Failed to get Gallery.');
        state.pendingGetGalleries = false;
      })
      .addCase(getUserDeviceSchedules.fulfilled, (state, { payload }) => {
        state.pendingGetDeviceSchedules = false;
        state.notificationSchedules = payload;
      })
      .addCase(getUserDeviceSchedules.pending, (state) => {
        state.pendingGetDeviceSchedules = true;
      })
      .addCase(getUserDeviceSchedules.rejected, (state) => {
        openErrorNotification('Failed to get Device Schedules.');
        state.pendingGetDeviceSchedules = false;
      })
      .addCase(createUserDeviceSchedule.fulfilled, (state) => {
        state.pendingCreateDeviceSchedules = false;
      })
      .addCase(createUserDeviceSchedule.pending, (state) => {
        state.pendingCreateDeviceSchedules = true;
      })
      .addCase(createUserDeviceSchedule.rejected, (state) => {
        openErrorNotification('Failed to add Device Schedule.');
        state.pendingCreateDeviceSchedules = false;
      })
      .addCase(patchUserDeviceSchedule.fulfilled, (state) => {
        state.pendingPatchDeviceSchedules = false;
      })
      .addCase(patchUserDeviceSchedule.pending, (state) => {
        state.pendingPatchDeviceSchedules = true;
      })
      .addCase(patchUserDeviceSchedule.rejected, (state) => {
        openErrorNotification('Failed to update Device Schedule.');
        state.pendingPatchDeviceSchedules = false;
      })
      .addCase(deleteUserDeviceSchedule.fulfilled, (state) => {
        state.pendingDeleteDeviceSchedules = false;
      })
      .addCase(deleteUserDeviceSchedule.pending, (state) => {
        state.pendingDeleteDeviceSchedules = true;
      })
      .addCase(deleteUserDeviceSchedule.rejected, (state) => {
        openErrorNotification('Failed to delete Device Schedule.');
        state.pendingDeleteDeviceSchedules = false;
      });
  },
});

export const { setRole } = slice.actions;

export const selectPendingGetDeviceSchedules = (state) =>
  state.users.pendingGetDeviceSchedules;
export const selectPendingGetNotifications = (state) =>
  state.users.pendingGetNotifications;
export const selectPendingCreateDeviceSchedules = (state) =>
  state.users.pendingCreateDeviceSchedules;
export const selectPendingPatchDeviceSchedules = (state) =>
  state.users.pendingPatchDeviceSchedules;
export const selectPendingDeleteDeviceSchedules = (state) =>
  state.users.pendingDeleteDeviceSchedules;
export const selectPendingGet = (state) => state.users.pendingGet;
export const selectPendingContactForm = (state) =>
  state.users.pendingContactForm;
export const selectPendingGetGalleries = (state) =>
  state.users.pendingGetGalleries;
export const selectPendingMe = (state) => state.users.pendingMe;
export const selectPendingPatch = (state) => state.users.pendingPatch;
export const selectPendingUpdateAvatar = (state) =>
  state.users.pendingUpdateAvatar;
export const selectUsers = (state) => state.users.list;
export const selectUserMe = (state) => state.users.me;
export const selectUserRole = (state) => {
  return localStorage.getItem('roles') || state.users.role;
};
export const selectGallery = (state) => state.users.gallery;
export const selectTotalGallery = (state) => state.users.totalGallery;
export const selectNotificationSchedule = (state) =>
  state.users.notificationSchedules;
export const selectNotifications = (state) => state.users.notifications;
export const selectDeviceFavorites = (state) => state.users.deviceFavorites;

export default slice.reducer;
