import React from 'react';
import { Link } from 'react-router-dom';
import Box from 'components/Box';
import Text from 'components/Text';
import {
  FlagOutlined,
  ArrowRightOutlined,
  BulbOutlined,
  SendOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

const BoxLink = styled(Box)`
  &:hover {
    border: 1px solid #5466e0;
  }
`;

function HelpNav() {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Link to="/app/help/getting-started">
        <BoxLink
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          maxWidth={900}
          m="0 auto 20px"
          border="1px solid"
          borderColor="gray"
          p={4}
          borderRadius={4}
        >
          <Box display="flex" alignItems="center">
            <Box
              width={48}
              height={48}
              display="flex"
              alignItems="center"
              justifyContent="center"
              backgroundColor="#E3E8F4"
              borderRadius="50%"
            >
              <FlagOutlined style={{ color: '#577BDC' }} />
            </Box>
            <Box ml={3}>
              <Text color="black" fontSize={18} fontWeight={600}>
                Getting Started
              </Text>
              <Text fontSize={14} color="black">
                Articles to get you up and running, quick and easy.
              </Text>
            </Box>
          </Box>
          <ArrowRightOutlined />
        </BoxLink>
      </Link>
      <Link to="/app/help/knowledge-based">
        <BoxLink
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          maxWidth={900}
          m="0 auto 20px"
          border="1px solid"
          borderColor="gray"
          p={4}
          borderRadius={4}
        >
          <Box display="flex" alignItems="center">
            <Box
              width={48}
              height={48}
              display="flex"
              alignItems="center"
              justifyContent="center"
              backgroundColor="#FBEAE7"
              borderRadius="50%"
            >
              <BulbOutlined style={{ color: '#C6786A' }} />
            </Box>
            <Box ml={3}>
              <Text color="black" fontSize={18} fontWeight={600}>
                Knowledge Based
              </Text>
              <Text fontSize={14} color="black">
                Helps you to get things around our product.
              </Text>
            </Box>
          </Box>
          <ArrowRightOutlined />
        </BoxLink>
      </Link>
      <Link to="/app/help/contact-and-support">
        <BoxLink
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          maxWidth={900}
          m="0 auto 20px"
          border="1px solid"
          borderColor="gray"
          p={4}
          borderRadius={4}
        >
          <Box display="flex" alignItems="center">
            <Box
              width={48}
              height={48}
              display="flex"
              alignItems="center"
              justifyContent="center"
              backgroundColor="#E0F7DD"
              borderRadius="50%"
            >
              <SendOutlined style={{ color: '#65C958' }} />
            </Box>
            <Box ml={3}>
              <Text color="black" fontSize={18} fontWeight={600}>
                Contact and Support
              </Text>
              <Text fontSize={14} color="black">
                Let us know your concerns, we’ll do our best to assist you.
              </Text>
            </Box>
          </Box>
          <ArrowRightOutlined />
        </BoxLink>
      </Link>
    </Box>
  );
}

export default HelpNav;
