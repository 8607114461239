import React from 'react';
import Box from 'components/Box';
import Text from 'components/Text';
import { FlagOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import { useHistory } from 'react-router-dom';

const { Panel } = Collapse;

function HelpGettingStarted() {
  const history = useHistory();

  return (
    <Box maxWidth={900} m="auto">
      <Button
        type="text"
        icon={<ArrowLeftOutlined />}
        onClick={() => history.push('/app/help')}
      >
        Back
      </Button>
      <Box display="flex" alignItems="center" mt={4} mb={6}>
        <Box
          width={48}
          height={48}
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="#E3E8F4"
          borderRadius="50%"
        >
          <FlagOutlined style={{ color: '#577BDC' }} />
        </Box>
        <Box ml={3}>
          <Text color="black" fontSize={18} fontWeight={600}>
            Getting Started
          </Text>
          <Text fontSize={14} color="black">
            Articles to get you up and running, quick and easy.
          </Text>
        </Box>
      </Box>
      <Box borderRadius={4} border="1px solid" borderColor="gray" mb={4}>
        <Collapse defaultActiveKey={['1']} ghost expandIconPosition="right">
          <Panel header="Setting up new camera" key="1">
            <Text>
              Synth id ugh pickled blog proident la croix, vinyl lyft distillery
              chambray meditation actually butcher before they sold out. VHS
              selfies pinterest consequat snackwave cold-pressed lomo vinyl
              selvage. Disrupt hammock VHS shaman eu. Fixie aliquip bitters
              pickled shoreditch af before they sold out polaroid farm-to-table.
              Shoreditch paleo sartorial hot chicken drinking vinegar.
            </Text>
          </Panel>
        </Collapse>
      </Box>
      <Box borderRadius={4} border="1px solid" borderColor="gray" mb={4}>
        <Collapse ghost expandIconPosition="right">
          <Panel header="Manage users and collaborators" key="1">
            <Text>
              Amet voluptate sit ut occaecat ex anim do. Dolore labore officia
              velit reprehenderit est cupidatat. Adipisicing ullamco labore
              velit ex. Irure officia laboris sint dolore mollit eu labore esse
              excepteur sit sint sunt qui. Ad cupidatat occaecat eiusmod mollit
              veniam velit. Amet laboris exercitation ex veniam consectetur
              occaecat mollit velit fugiat ut irure laboris tempor. Dolore
              aliqua dolore esse aute.
            </Text>
          </Panel>
        </Collapse>
      </Box>
      <Box borderRadius={4} border="1px solid" borderColor="gray" mb={4}>
        <Collapse ghost expandIconPosition="right">
          <Panel header="Camera AI setup" key="1">
            <Text>
              Fugiat tempor incididunt qui aliquip tempor eiusmod veniam amet
              tempor voluptate. Eiusmod quis exercitation laborum esse magna
              commodo et amet nostrud enim consectetur. Consequat Lorem enim
              elit mollit minim dolor. Amet sit ad elit sint aliquip nulla
              aliquip tempor id. Minim consectetur nostrud deserunt laborum
              officia labore cupidatat aliqua Lorem dolore irure nostrud elit
              cillum. Enim esse enim pariatur elit. Ea est laboris consequat ex.
            </Text>
          </Panel>
        </Collapse>
      </Box>
      <Box borderRadius={4} border="1px solid" borderColor="gray" mb={4}>
        <Collapse ghost expandIconPosition="right">
          <Panel header="Shared devices" key="1">
            <Text>
              Consequat aute adipisicing duis aliqua exercitation Lorem in
              labore nulla ullamco labore sit aliqua irure. Consectetur sunt
              nostrud cillum tempor deserunt laboris occaecat officia esse sunt
              Lorem in. Excepteur ullamco ex ipsum aliqua pariatur esse officia
              occaecat labore proident sit nulla nulla ad.
            </Text>
          </Panel>
        </Collapse>
      </Box>
    </Box>
  );
}

export default HelpGettingStarted;
