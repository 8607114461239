import axios, { AxiosInstance } from 'axios';
import { message, notification } from 'antd';
import applyCaseMiddleware from 'axios-case-converter';

const caseMiddleware = {
  requestTransformer: (config: any) => {
    // Disable query string transformation
    return config;
  },
};

export const handleAPIError = (errorResponse: any, errorTitle: string) => {
  const { data } = errorResponse;

  if (errorResponse.status !== 403) {
    if (data.message) {
      notification.error({
        message: `API Error: ${errorTitle}`,
        duration: 5,
        description: data.message,
        placement: 'bottomLeft',
      });
    } else {
      notification.error({
        message: `Error: ${errorTitle}`,
        duration: 5,
        description: 'Something went wrong.',
        placement: 'bottomLeft',
      });
    }
  }
};

const timeout = 60_000;

const handleResponse = (response: any) => {
  let result;

  const { code, message: msg, data } = response.data;
  if (code === 0) {
    // success
    result = data;
  } else if (msg === 'jwt expired') {
    const host = `${window.location.protocol}//${window.location.host}`;
    window.location.replace(host);
    message.info('Session token has expired, Please login again.');
  } else {
    message.error(`ERROR: ${msg}`);
  }

  return result;
};

const api: AxiosInstance = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    // withCredentials: true,
    headers: {
      Accept: 'application/json',
      // 'Access-Control-Allow-Origin': true,
    },
  }),
  { caseMiddleware, preservedKeys: ['deviceId'] },
);

api.interceptors.response.use(handleResponse, (error) => {
  handleAPIError(error, 'API Error');
  return Promise.reject(error);
});

export function createAuthenticatedAPI(): AxiosInstance {
  const authenticatedApi = applyCaseMiddleware(
    axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Content-Type': 'application/json',
      },
    }),
    {
      ignoreHeaders: false,
      caseMiddleware,
      preservedKeys: ['deviceId'],
    },
  );

  authenticatedApi.interceptors.response.use(handleResponse, (error) => {
    handleAPIError(error, 'API Error');
    return Promise.reject(error);
  });

  return authenticatedApi;
}

export default api;
