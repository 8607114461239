import styled from 'styled-components';

export const DeviceAddModal = styled.div`
  .selected-plan {
    .ant-card-head {
      background: #5466e0;
      color: #fff;
    }
    .ant-card-body {
      border: 1px solid #5466e0;
    }
  }

  .credit-card-box {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 8px;
  }

  .add-new-group {
    color: #5466e0;
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: -6px;
    z-index: 2;
  }

  .add-new-subscription {
    color: #5466e0;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    width: 100%;
  }

  .cards-list {
    .ant-radio {
      top: 0;
    }
  }
`;
