import React from 'react';
import Box from 'components/Box';
import Text from 'components/Text';
import { Button, Row, Col, Form, Input, Select } from 'antd';
import {
  SendOutlined,
  ArrowLeftOutlined,
  MailOutlined,
  PhoneOutlined,
  PushpinOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const { Option } = Select;
function HelpContactSupport() {
  const history = useHistory();

  const handleOnFinish = (val) => {
    // eslint-disable-next-line no-console
    console.log(val);
  };

  return (
    <Box maxWidth={900} m="auto">
      <Button
        type="text"
        icon={<ArrowLeftOutlined />}
        onClick={() => history.push('/app/help')}
      >
        Back
      </Button>
      <Row gutter={20}>
        <Col span={14}>
          <Box display="flex" alignItems="center" mt={4} mb={6}>
            <Box
              width={48}
              height={48}
              display="flex"
              alignItems="center"
              justifyContent="center"
              backgroundColor="#E0F7DD"
              borderRadius="50%"
            >
              <SendOutlined style={{ color: '#65C958' }} />
            </Box>
            <Box ml={3}>
              <Text color="black" fontSize={18} fontWeight={600}>
                Coontact and Support
              </Text>
              <Text fontSize={14} color="black">
                Let us know your concerns, we&apos;ll do our best to assist you.
              </Text>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <MailOutlined style={{ fontSize: '21px' }} />
            <Text ml={2}>support@imageengine.ai</Text>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <PhoneOutlined style={{ fontSize: '21px' }} />
            <Text ml={2}>+64 3 345 6789</Text>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <LinkOutlined style={{ fontSize: '21px' }} />
            <Text ml={2}>www.imageengine.ai</Text>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <PushpinOutlined style={{ fontSize: '21px' }} />
            <Text ml={2}>124 Weka Place, Te Ore Ore, Masterton 5810</Text>
          </Box>
        </Col>
        <Col span={10}>
          <Form layout="vertical" onFinish={handleOnFinish}>
            <Form.Item label="Category" name="category">
              <Select defaultValue="1">
                <Option value="1">Product Support</Option>
                <Option value="2">Option 2</Option>
                <Option value="3">Option 3</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Tell us your concerns" name="concerns">
              <Input.TextArea
                allowClear
                autoSize={{ minRows: 5, maxRows: 5 }}
              />
            </Form.Item>
            <Button htmlType="submit" type="primary" size="large" block>
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Box>
  );
}

export default HelpContactSupport;
