import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  handleAPIError,
  openErrorNotification,
  openSuccessNotification,
} from 'common/helpers';

export const deviceCustomerSubscriptionCreate = createAsyncThunk(
  'device/customer/subscription/create',
  async (
    { customerId, payload },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.post(
        // `/reseller/users/${customerId}/subscriptions`,
        `/subscription/subscriptions`,
        { ...payload, customerId },
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deviceSubscriptionGet = createAsyncThunk(
  'device/subscription/get',
  async (payload, { extra: { createAuthenticatedAPI }, rejectWithValue }) => {
    const api = createAuthenticatedAPI();
    try {
      const data = await api.get(`/subscription/subscriptions/${payload}`);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deviceSubscriptionsGet = createAsyncThunk(
  'device/subscriptions/get',
  async (_, { extra: { createAuthenticatedAPI }, rejectWithValue }) => {
    const api = createAuthenticatedAPI();
    try {
      const response = await api.get('/subscription/subscriptions/');
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deviceSubscriptionChange = createAsyncThunk(
  'device/subscriptions/change',
  async (
    { subscriptionId, payload },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      const { data } = await api.post(
        `/subscription/subscriptions/${subscriptionId}/change`,
        payload,
      );

      openSuccessNotification({
        message: 'Subscription Updated!',
        description: 'You have successfully updated your Plan',
      });

      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deviceSubscriptionDeleteImmediately = createAsyncThunk(
  'device/subscription/deleteImmediately',
  async (
    { subscriptionId },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.delete(`/subscription/subscriptions/${subscriptionId}`);
    } catch (err) {
      handleAPIError(err, 'Delete Device');
      return rejectWithValue(err);
    }
  },
);

export const deviceSubscriptionCancel = createAsyncThunk(
  'device/subscription/cancel',
  async (
    { subscriptionId, reason },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.post(
        `/subscription/subscriptions/${subscriptionId}/cancel`,
        { reason: reason || 'Delete from Device List' },
      );
    } catch (err) {
      handleAPIError(err, 'Cancel Subscription');
      return rejectWithValue(err);
    }
  },
);

export const deviceRevertCancelSubscription = createAsyncThunk(
  'device/subscription/revert-cancel',
  async (
    { subscriptionId },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.delete(
        `/subscription/subscriptions/${subscriptionId}/cancel`,
      );
    } catch (err) {
      handleAPIError(err, 'Revert Cancel Subscription');
      return rejectWithValue(err);
    }
  },
);

export const deviceDelete = createAsyncThunk(
  'device/Delete',
  async (
    { deviceId },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.delete(`/device/${deviceId}`);
    } catch (err) {
      handleAPIError(err, 'Delete Device');
      return rejectWithValue(err);
    }
  },
);

const initialState = {
  deviceSubscription: {},
  deviceSubscriptions: [],
  pending: false,
  pendingDeleteImmediately: false,
  pendingChange: false,
  isModalActive: false,
  error: null,
};

export const slice = createSlice({
  name: 'deviceSubscription',
  initialState,
  reducers: {
    setModalActive: (state, action) => {
      state.isModalActive = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deviceCustomerSubscriptionCreate.fulfilled, (state) => {
        state.pending = false;
      })
      .addCase(deviceCustomerSubscriptionCreate.pending, (state) => {
        state.pending = true;
      })
      .addCase(deviceCustomerSubscriptionCreate.rejected, (state) => {
        state.pending = false;
      })
      .addCase(deviceSubscriptionGet.fulfilled, (state, { payload }) => {
        state.pending = false;
        state.deviceSubscription = payload;
      })
      .addCase(deviceSubscriptionGet.pending, (state) => {
        state.pending = true;
      })
      .addCase(deviceSubscriptionGet.rejected, (state) => {
        openErrorNotification('Failed to get Device Subscription.');
        state.pending = false;
      })
      .addCase(deviceSubscriptionsGet.fulfilled, (state, { payload }) => {
        state.pending = false;
        state.deviceSubscriptions = payload;
      })
      .addCase(deviceSubscriptionsGet.pending, (state) => {
        state.pending = true;
      })
      .addCase(deviceSubscriptionsGet.rejected, (state) => {
        state.pending = false;
      })
      .addCase(deviceSubscriptionChange.fulfilled, (state, { payload }) => {
        state.pendingChange = false;
        state.deviceSubscriptions = payload;
      })
      .addCase(deviceSubscriptionChange.pending, (state) => {
        state.pendingChange = true;
      })
      .addCase(deviceSubscriptionChange.rejected, (state) => {
        openErrorNotification('Failed to change Device Subscriptions.');
        state.pendingChange = false;
      })
      .addCase(deviceSubscriptionDeleteImmediately.fulfilled, (state) => {
        openSuccessNotification({
          message: 'Subscription Cancelled!',
          description: 'You have successfully cancelled a subscription',
        });
        state.pendingDeleteImmediately = false;
      })
      .addCase(deviceSubscriptionDeleteImmediately.pending, (state) => {
        state.pendingDeleteImmediately = true;
      })
      .addCase(deviceSubscriptionDeleteImmediately.rejected, (state) => {
        openErrorNotification('Failed to delete Subscriptions.');
        state.pendingDeleteImmediately = false;
      });
  },
});

export const { setModalActive } = slice.actions;

export const selectDeviceSubscription = (state) =>
  state.deviceSubscription.deviceSubscription;
export const selectDeviceSubscriptions = (state) =>
  state.deviceSubscription.deviceSubscriptions;
export const selectModalActive = (state) =>
  state.deviceSubscription.isModalActive;

export const selectPendingChange = (state) =>
  state.deviceSubscription.pendingChange;
export const selectPending = (state) => state.deviceSubscription.pending;

export default slice.reducer;
