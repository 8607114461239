import React from 'react';
import CustomerList from 'features/CustomerList';
import CustomerAdd from 'features/CustomerAdd';
import { Switch, Route } from 'react-router-dom';

function CustomersPage() {
  return (
    <Switch>
      <Route path="/app/customers/new">
        <CustomerAdd />
      </Route>
      <Route exact path="/app/customers">
        <CustomerList />
      </Route>
    </Switch>
  );
}

export default CustomersPage;
