import React, { useState } from 'react';
import {
  Space,
  Select,
  Row,
  Col,
  Typography,
  Button,
  Form,
  Input,
  Tooltip,
  message,
} from 'antd';
import {
  CreditCardOutlined,
  MenuOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import useDeviceList from 'features/DeviceList/hooks';
import Box from 'components/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import {
  retrieveDevice,
  selectDevice,
  deviceUpdate,
  selectPendingUpdate,
} from 'features/DeviceDetail/slice';
import * as S from './styles';

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

function DeviceDetailsUpdate(props) {
  const { deviceId } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const device = useSelector(selectDevice);
  const pendingUpdate = useSelector(selectPendingUpdate);

  useEffectOnce(() => {
    dispatch(retrieveDevice({ id: deviceId }));
  });

  useUpdateEffect(() => {
    dispatch(retrieveDevice({ id: deviceId }));
  }, [deviceId]);

  useUpdateEffect(() => {
    const { name, description, deviceGroupId } = device;
    form.setFieldsValue({ name, description, deviceGroupId });
  }, [device]);

  const handleDeviceUpdate = async (formData) => {
    const response = await dispatch(deviceUpdate({ deviceId, ...formData }));
    if (response.meta.requestStatus === 'fulfilled') {
      message.success('Device Details Updated!');
    }
  };

  const [createNewGroup, setCreateNewGroup] = useState(false);

  const { groupedDevices } = useDeviceList();

  return (
    <S.StyledDeviceDetails>
      <Form form={form} onFinish={handleDeviceUpdate} layout="vertical">
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Description" name="description">
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          {createNewGroup || groupedDevices.length === 0 ? (
            <Col span={24}>
              {groupedDevices.length !== 0 && (
                <React.Fragment>
                  <Button
                    type="link"
                    className="add-new-group"
                    onClick={() => setCreateNewGroup(false)}
                  >
                    Click here to select from existing groups
                  </Button>
                </React.Fragment>
              )}
              <Form.Item
                label="New Group"
                name="newDeviceGroupName"
                rules={[{ required: true, message: 'This field is required!' }]}
              >
                {groupedDevices.length === 0 ? (
                  <Input />
                ) : (
                  <Input
                    suffix={
                      <Tooltip title="Use existing Device Group">
                        <MenuOutlined
                          onClick={() => setCreateNewGroup(false)}
                          style={{ cursor: 'pointer' }}
                        />
                      </Tooltip>
                    }
                  />
                )}
              </Form.Item>
            </Col>
          ) : (
            <Col span={24}>
              <Button
                type="link"
                className="add-new-group"
                onClick={() => setCreateNewGroup(true)}
              >
                Click here to create new group
              </Button>
              <Form.Item
                label="Select from existing groups"
                name="deviceGroupId"
                rules={[{ required: true, message: 'This field is required!' }]}
              >
                <Select
                  suffixIcon={
                    <Tooltip title="Create New Device Group">
                      <PlusOutlined
                        onClick={() => setCreateNewGroup(true)}
                        style={{ cursor: 'pointer', color: '#333' }}
                      />
                    </Tooltip>
                  }
                >
                  {groupedDevices.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <div className="flex w-full justify-end">
          <Button
            className="!w-auto"
            htmlType="submit"
            type="primary"
            loading={pendingUpdate}
            block
          >
            Change Details
          </Button>
        </div>
      </Form>
    </S.StyledDeviceDetails>
  );
}

export default DeviceDetailsUpdate;
