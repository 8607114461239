import React from 'react';
import Statistics from 'features/Statistics';

import * as S from './styles';

function StatisticsPage() {
  return (
    <S.StatisticsPage>
      <Statistics />
    </S.StatisticsPage>
  );
}

export default StatisticsPage;
