import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState, ThunkApiFields } from 'app/store';
import { IDataTableFilter, IPaginatedResponse } from 'global/types';

import axios from 'axios';

import { handleAPIError } from 'app/client';

export const getPaginatedData = createAsyncThunk<
  IPaginatedResponse<any>,
  { filter: IDataTableFilter; type: string },
  ThunkApiFields
>(
  'getPaginatedData',
  async (
    { type, filter },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    try {
      const api = createAuthenticatedAPI();
      const baseApiUrl: { [key: string]: string } = {
        registrations: `/admin/user/registrations`,
        orders: `/admin/order/orders`,
        order_items: `/admin/order/items`,
      };

      const response = await api.get(baseApiUrl[type.split('-')[0]], {
        params: {
          search: filter.search,
          page: filter.page ?? 1,
          limit: filter.limit ?? 30,
        },
      });

      return response.data.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        handleAPIError(err.response, 'Failed to get paginated data');
        return rejectWithValue(err.response.data);
      }
      throw err;
    }
  },
);

export interface IPaginatedState {
  pending: boolean;
  errors: unknown;
  paginatedResponse: IPaginatedResponse<any>;
  sort: {
    column: string;
    direction: string;
  }[];
  filters: any[];
  pagination: {
    page: number;
    limit: number;
  };
}

type InitialState = {
  [key: string]: IPaginatedState;
};

export const defaultPaginatedState = {
  pending: false,
  errors: null,
  paginatedResponse: {
    data: [],
    total: 0,
    limit: 30,
    skip: 0,
  },
  pagination: {
    page: 1,
    limit: 30,
  },
  sort: [],
  filters: [],
};

const initialState: InitialState = {};

export const slice = createSlice({
  name: 'paginated',
  initialState,
  reducers: {
    setPagination: (state, action) => {
      const { type, pagination } = action.payload;
      state[type].pagination = pagination;
    },
    setSort: (state, action) => {
      const { type, sort } = action.payload;
      state[type].sort = sort;
    },
    setFilters: (state, action) => {
      const { type, filters } = action.payload;
      state[type].filters = filters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaginatedData.pending, (state, action) => {
        const { type } = action.meta.arg;
        if (!state[type]) {
          state[type] = { ...defaultPaginatedState };
        }

        state[type].pending = true;
      })
      .addCase(getPaginatedData.fulfilled, (state, action) => {
        const { type } = action.meta.arg;
        state[type].pending = false;
        state[type].paginatedResponse = action.payload;
      })
      .addCase(getPaginatedData.rejected, (state, action) => {
        const { type } = action.meta.arg;

        state[type].pending = false;
        state[type].errors = action.payload;
      });
  },
});

export const { setPagination, setSort, setFilters } = slice.actions;

export const selectPaginated = (state: RootState) => state.paginated;

export default slice.reducer;
