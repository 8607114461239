import styled from 'styled-components';
import colors from 'styles/colors';

export const Gallery = styled.div`
  padding: 20px;
  background: ${colors.gray_1};
  min-height: calc(100vh - 56px);

  .ant-pagination-simple .ant-pagination-simple-pager input {
    padding: 0;
    width: 45px;
  }

  .photo-list-wrapper {
    height: calc(100vh - 180px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 6px;
    margin-top: 20px;

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dedede;
    }
  }
`;

export const Thumbnail = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 3px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
