import React from 'react';

import { useUpdateEffect } from 'react-use';
import { Select, Typography, Divider } from 'antd';
import { RobotOutlined } from '@ant-design/icons';
import useDeviceList from 'features/DeviceList/hooks';
import Manage from './components/Manage';
import Invite from './components/Invite';

const { Option } = Select;

function DeviceSharing(props) {
  const { deviceId } = props;
  const { allDevices } = useDeviceList();
  const [selectedDeviceId, setSelectedDeviceId] = React.useState(deviceId);

  useUpdateEffect(() => {
    if (allDevices.length > 0) {
      setSelectedDeviceId(deviceId || allDevices[0].id);
    }
  }, [allDevices, deviceId]);

  return (
    <div className="relative flex min-h-[calc(100vh-110px)] flex-col">
      {!deviceId && allDevices.length > 0 && (
        <div className="flex flex-col gap-y-3 p-[30px] !pb-0">
          <div className="flex items-center gap-x-2 align-middle">
            <RobotOutlined style={{ fontSize: '21px' }} />
            <Typography.Title level={4} style={{ margin: '0' }}>
              Device Sharing
            </Typography.Title>
          </div>

          <Select
            onChange={(val) => setSelectedDeviceId(val)}
            defaultValue={allDevices[0]?.id}
            style={{ width: '100%', marginBottom: '20px' }}
          >
            {allDevices.map((device) => {
              if (
                device.isOwner === 1 ||
                device.deviceUserAllowManageUser === 1
              ) {
                return (
                  <Option value={device.id} key={device.id}>
                    {`[${device.deviceGroupName}] - ${device.name} - ${device.description}`}
                  </Option>
                );
              }
              return null;
            })}
          </Select>
        </div>
      )}
      <div className="flex flex-col gap-y-2 px-[30px] align-middle">
        <Divider orientation="left">Invite Users</Divider>
        <Invite deviceId={selectedDeviceId} />
        <Divider orientation="left"> Manage Users</Divider>
        <Manage deviceId={selectedDeviceId} />
      </div>
    </div>
  );
}

export default DeviceSharing;
