import React, { useState, useRef } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SideNav from 'components/SideNav';
import TopNav from 'components/TopNav';

import AddDeviceDrawer from 'features/DeviceAddDrawer';
import DeviceListPage from 'pages/DeviceListPage';

import DeviceDetailPage from 'pages/DeviceDetailPage';
import CustomersPage from 'pages/CustomersPage';
import CustomerDeviceListPage from 'pages/CustomerDeviceListPage';
import FavoritesPage from 'pages/FavoritesPage';
import GalleryPage from 'pages/GalleryPage';
import StatisticsPage from 'pages/StatisticsPage';
import SettingsPage from 'pages/SettingsPage';
import CheckoutPage from 'pages/CheckoutPage';
import HelpPage from 'pages/HelpPage';

import { useClickAway, useEffectOnce } from 'react-use';
import TransactionPage from 'pages/TransactionPage';

import { useAuth0 } from '@auth0/auth0-react';

import { LoadingOutlined } from '@ant-design/icons';

import { useSession } from 'service/session/hooks';
import * as S from './styles';

function AuthenticatedPage() {
  const { getAccessTokenSilently } = useAuth0();
  const { getUserMe } = useSession();

  const [isReady, setIsReady] = React.useState(false);

  const sideNavRef = useRef(null);
  const [isSideNavActive, setSideNav] = useState(false);

  useClickAway(sideNavRef, () => {
    setSideNav(false);
  });

  useEffectOnce(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            redirect_uri: window.location.origin,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: 'openid:profile',
          },
        });

        if (token) {
          localStorage.setItem('auth-token', token);
          await getUserMe();
          setIsReady(true);
        }
      } catch (e) {
        // login();
        // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
        // eslint-disable-next-line no-console
        console.error('Error with getAccessTokenSilently!', e);
      }
    })();
  });

  if (!isReady) {
    return (
      <div className="flex h-[calc(100vh)] w-full items-center justify-center pt-10 align-middle">
        <LoadingOutlined style={{ fontSize: '100px', color: '#acacac' }} />
      </div>
    );
  }

  return (
    <S.AuthenticatedPage>
      <aside ref={sideNavRef} className={isSideNavActive ? 'active' : ''}>
        <SideNav setSideNav={setSideNav} />
      </aside>
      <section>
        <TopNav setSideNav={setSideNav} />
        <Switch>
          <Route exact path="/app/devices">
            <DeviceListPage />
          </Route>
          <Route exact path="/app/devices/:id">
            <DeviceDetailPage />
          </Route>
          <Route exact path="/app/devices/:id/photo/:photoId">
            <DeviceDetailPage />
          </Route>
          <Route exact path="/app/customers">
            <CustomersPage />
          </Route>
          <Route exact path="/app/customers/:customerId/devices">
            <CustomerDeviceListPage />
          </Route>
          <Route exact path="/app/favorites">
            <FavoritesPage />
          </Route>
          <Route exact path="/app/gallery">
            <GalleryPage />
          </Route>
          <Route exact path="/app/statistics">
            <StatisticsPage />
          </Route>
          <Route path="/app/settings">
            <SettingsPage />
          </Route>
          <Route path="/app/checkout">
            <CheckoutPage />
          </Route>
          <Route path="/app/help">
            <HelpPage />
          </Route>
          <Route exact path="/app/transactions/:invoiceId">
            <TransactionPage />
          </Route>
          <Route exact path="/app/transactions">
            <TransactionPage />
          </Route>
          <Redirect from="/app/" to="/app/devices" />
        </Switch>
      </section>
      <AddDeviceDrawer />
    </S.AuthenticatedPage>
  );
}

export default AuthenticatedPage;
