import { notification, message as antMessage, Tag } from 'antd';
import colors from 'styles/colors';

import dayjs from 'dayjs';
import _, { toLower } from 'lodash';
import React, { Fragment } from 'react';

export const isEmpty = (value) => {
  return _.isNil(value) || _.trim(value) === '';
};

export const compact = (...values) => {
  return _.filter(_.flattenDeep(values), (value) => !isEmpty(value));
};

export const getRoles = (user) => {
  return user?.[process.env.REACT_APP_AUTH0_ROLE_DOMAIN].roles;
};

export const handleAPIError = (errorResponse, errorTitle) => {
  const { data } = errorResponse;

  // 403 error is handled in app/client.ts
  if (errorResponse.status !== 403) {
    const issues = Object.keys(data).map((key) => data[key].toString());

    if (data?.detail) {
      antMessage.info(data.detail, 4);
    } else if (issues.length > 0) {
      notification.info({
        message: errorTitle,
        placement: 'bottomLeft',
        description: (
          <p>
            {issues.map((issue) => (
              <Fragment key={issue}>
                {issue}
                <br />
              </Fragment>
            ))}
          </p>
        ),
      });
    } else if (data.nonFieldErrors) {
      antMessage.info(data.nonFieldErrors, 4).then();
    } else {
      antMessage.error('Something went wrong.').then();
    }
  }
};

export const compare = (left, right) => {
  const leftLowerCase = toLower(left);
  const rightLowerCase = toLower(right);

  if (leftLowerCase < rightLowerCase) {
    return -1;
  }
  if (leftLowerCase > rightLowerCase) {
    return 1;
  }
  // names must be equal
  return 0;
};

export const formatDate = (date, format = 'MMM DD, YYYY h:mm a') => {
  const parsedDate = new Date(date);

  if (dayjs(parsedDate).isValid()) return dayjs(parsedDate).format(format);
  return '';
};

export function formatNumber(num, decimal = 0) {
  return parseFloat(num.toString())
    .toFixed(decimal)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function isValidURL(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
}

export const arrToObj = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const sortByName = (a, b) => {
  let result = 0;
  if (a.name < b.name) result = -1;
  else if (a.name > b.name) result = 1;

  return result;
};

export const openSuccessNotification = (config) => {
  notification.success(config);
};

export const openErrorNotification = (errorMessage) => {
  notification.error({
    message: 'Error',
    description: errorMessage.toString(),
    duration: 10,
    placement: 'bottomLeft',
  });
};

export const bytesToSize = (bytes, index = 0) => {
  const sizes = ['KB', 'MB', 'GB', 'TB'];
  if (bytes < 1_000) return `${bytes.toFixed(1)} ${sizes[index]}`;
  return bytesToSize(bytes / 1_000, index + 1);
};

export const mbToSize = (mb) => {
  return bytesToSize(mb, 1);
};

export const renderPlanTag = (plan) => {
  let color;
  let name;

  switch (plan) {
    case 'gold':
      color = colors.gold;
      name = 'Gold';
      break;
    case 'silver':
      color = colors.silver;
      name = 'Silver';
      break;
    case 'bronze':
      color = colors.bronze;
      name = 'Bronze';
      break;
    case 'copper':
      color = colors.copper;
      name = 'Copper';
      break;
    default:
      break;
  }
  return <Tag color={color}>{name}</Tag>;
};
