import React from 'react';
import Gallery from 'features/Gallery';

import * as S from './styles';

function GalleryPage() {
  return (
    <S.GalleryPage>
      <Gallery />
    </S.GalleryPage>
  );
}

export default GalleryPage;
