import React from 'react';
import RegisterForm from 'features/RegisterForm';
import OnboardingSlider from 'components/OnboardingSlider';
import GuestNav from 'components/GuestNav';

import * as S from './styles';

function RegisterPage() {
  return (
    <S.RegisterPage>
      <GuestNav />
      <S.Content>
        <section>
          <RegisterForm />
        </section>
        <aside>
          <OnboardingSlider />
        </aside>
      </S.Content>
    </S.RegisterPage>
  );
}

export default RegisterPage;
