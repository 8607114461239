import { useSelector, useDispatch } from 'react-redux';
import { sortByName } from 'common/helpers';
import { useEffectOnce } from 'react-use';
import { selectAllDevices, devicesGet } from './slice';

function useDeviceList() {
  const dispatch = useDispatch();
  const allDevices = useSelector(selectAllDevices);

  const groupBy = (previous, current) => {
    const existing = previous.find(
      (value) => value.id === current.deviceGroupId,
    );
    if (existing) {
      existing.devices.push(current);
    } else {
      previous.push({
        id: current.deviceGroupId,
        name: current.deviceGroupName,
        devices: [current],
      });
    }
    return previous;
  };

  const groupedDevices = allDevices.reduce(groupBy, []).sort(sortByName);

  const fetchData = async () => {
    dispatch(devicesGet());
  };

  useEffectOnce(() => {
    fetchData();
  });

  return { allDevices, groupedDevices };
}

export default useDeviceList;
