import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

function StripeHooks() {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      // eslint-disable-next-line no-console
      console.log('[error]', error);
    } else {
      // eslint-disable-next-line no-console
      console.log('[PaymentMethod]', paymentMethod);
    }
  };

  const handleOnBlur = () => {
    // eslint-disable-next-line no-console
    console.log('handleOnBlur:');
  };

  const handleOnChange = (object) => {
    // eslint-disable-next-line no-console
    console.log('handleOnChange:', object);
  };

  const handleOnClick = (object) => {
    // eslint-disable-next-line no-console
    console.log('handleOnClick:', object);
  };

  const handleOnFocus = () => {
    // eslint-disable-next-line no-console
    console.log('handleOnFocus:');
  };

  const handleOnReady = (element) => {
    // eslint-disable-next-line no-console
    console.log('handleOnReady:', element);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onClick={handleOnClick}
        onFocus={handleOnFocus}
        onReady={handleOnReady}
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
}

export default StripeHooks;
