import React from 'react';
import PageFilter from 'components/PageFilter';
import CheckOut from 'features/Checkout';
import * as S from './styles';

function CheckoutPage() {
  return (
    <S.StatisticsPage>
      <PageFilter title="CheckOut" />
      <CheckOut />
    </S.StatisticsPage>
  );
}

export default CheckoutPage;
