import { useSelector, useDispatch } from 'react-redux';
import { retrieveDevice } from 'features/DeviceDetail/slice';
import { devicesGet } from 'features/DeviceList/slice';

import {
  selectDeviceSubscription,
  selectDeviceSubscriptions,
  selectPending,
  selectPendingChange,
  deviceSubscriptionGet,
  deviceSubscriptionsGet,
  deviceSubscriptionChange,
  selectModalActive,
  setModalActive,
} from './slice';

function useDeviceSubscription() {
  const dispatch = useDispatch();
  const deviceSubscription = useSelector(selectDeviceSubscription);
  const deviceSubscriptions = useSelector(selectDeviceSubscriptions);
  const pending = useSelector(selectPending);
  const pendingChange = useSelector(selectPendingChange);

  const isModalActive = useSelector(selectModalActive);

  const showModal = (val) => {
    dispatch(setModalActive(val));
  };

  const changeDeviceSubscription = (subscriptionId, payload) => {
    return dispatch(deviceSubscriptionChange({ subscriptionId, payload })).then(
      () => {
        dispatch(retrieveDevice({ id: payload.deviceId }));
        dispatch(deviceSubscriptionGet(subscriptionId));
        dispatch(devicesGet());
        dispatch(setModalActive(false));
      },
    );
  };

  const getDeviceSubscription = ({ subscriptionId }) => {
    dispatch(deviceSubscriptionGet(subscriptionId));
  };

  const getSubscriptions = () => {
    dispatch(deviceSubscriptionsGet());
  };

  return {
    changeDeviceSubscription,
    getDeviceSubscription,
    getSubscriptions,
    deviceSubscription,
    deviceSubscriptions,
    pending,
    pendingChange,
    isModalActive,
    showModal,
  };
}

export default useDeviceSubscription;
