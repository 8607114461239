import React from 'react';
import { Row, Col } from 'antd';
import Box from 'components/Box';

import * as S from './styles';
import Cards from './Cards';
import PieChartSection from './PieChartSection';
import LineChartSection from './LineChartSection';

import { useGetReports } from './hooks';

function Statistics() {
  const [, { data }] = useGetReports();

  return (
    <S.Statistics>
      <Box>
        <Row gutter={[20, 20]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }}>
            <PieChartSection data={data} />
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }}>
            <Cards data={data} />
          </Col>
        </Row>
      </Box>
      <Box mt={4}>
        <LineChartSection />
      </Box>
    </S.Statistics>
  );
}

export default Statistics;
