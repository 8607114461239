import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import { Space, Button, Input, Avatar } from 'antd';

const { Search } = Input;

function Step3() {
  return (
    <Box
      maxWidth={670}
      width="100%"
      backgroundColor="white"
      border="1px solid"
      borderColor="gray"
      m="auto"
      py={35}
      px={100}
    >
      <Text as="h4" fontWeight={600} fontSize={18} mb={2}>
        Invite People (Optional)
      </Text>
      <Text fontSize={13} mb={6}>
        Add people in this account (you can’t add the owner of this account)
      </Text>
      <Search placeholder="Invite people e.g sarah@email.com" size="large" />
      <Box my={4}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid"
          borderBottomColor="gray"
          pb={2}
          mt={2}
        >
          <Box display="flex" alignItems="center">
            <Avatar size="large" src="https://picsum.photos/32/32" />
            <Box ml={2}>
              <Text fontSize={14} fontWeight={500}>
                Stefan Anston
              </Text>
              <Text fontSize={12} color="gray_2">
                stefan2211@gmail.com
              </Text>
            </Box>
          </Box>
          <Button type="default">Remove</Button>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid"
          borderBottomColor="gray"
          pb={2}
          mt={2}
        >
          <Box display="flex" alignItems="center">
            <Avatar size="large" src="https://picsum.photos/32/32" />
            <Box ml={2}>
              <Text fontSize={14} fontWeight={500}>
                Stefan Anston
              </Text>
              <Text fontSize={12} color="gray_2">
                stefan2211@gmail.com
              </Text>
            </Box>
          </Box>
          <Button type="default">Remove</Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Space>
          <Button type="default" size="large">
            Cancel
          </Button>
          <Button type="primary" size="large">
            Save Account
          </Button>
        </Space>
      </Box>
    </Box>
  );
}

export default Step3;
