import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { openErrorNotification } from 'common/helpers';

export const deviceAddOnPurchase = createAsyncThunk(
  'device/create',
  async (
    { subscriptionId, payload },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.post('/user/purchase', { ...payload, subscriptionId });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  pending: false,
};

export const slice = createSlice({
  name: 'deviceAddOn',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(deviceAddOnPurchase.fulfilled, (state) => {
        state.pending = false;
      })
      .addCase(deviceAddOnPurchase.pending, (state) => {
        state.pending = true;
      })
      .addCase(deviceAddOnPurchase.rejected, (state) => {
        openErrorNotification('Failed to purchase add-ons.');
        state.pending = false;
      });
  },
});

export const selectPending = (state) => state.deviceAddOn.pending;

export default slice.reducer;
