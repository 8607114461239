import auth0 from 'auth0-js';
import localStorage from 'localStorage';

const auth0Config = {
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  responseType: 'token id_token',
  scope: 'openid profile email',
  leeway: 30,
};
const Auth0 = new auth0.WebAuth(auth0Config);

export default Auth0;

function handleClearTokens() {
  const pass = localStorage.getItem('password');

  localStorage.clear();
  localStorage.setItem('password', pass);
  const cookies = document.cookie.split(';');

  if (cookies.length) {
    // eslint-disable-next-line array-callback-return
    cookies.forEach((cookie) => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    });
  }
}

function handleLogin() {
  Auth0.authorize({
    auth_database: process.env.REACT_APP_AUTH0_DATABASE,
    redirectUri: `${window.location.origin}/auth`,
  });
}

function handleLogout() {
  Auth0.logout({
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT,
  });
  handleClearTokens();
}

function handleValidateToken() {
  // const idTokenFromLocalStorage = localStorage.getItem('id_token');
  return true;
  // try {

  // const idToken = jwt.decode(idTokenFromLocalStorage);
  // const idToken = false;
  // if (idToken) {
  //   // if idToken is expired
  //   if (new Date(new Date().getTime()) > new Date(idToken.exp * 1000)) {
  //     handleClearTokens();
  //     return false;
  //   }
  //
  //   return true;
  // }
  // if idToken is not a valid jwt
  // handleClearTokens();
  // return false;
  // } catch (err) {
  //   // if idToken jwt is tampered
  //   handleClearTokens();
  //   return false;
  // }
}

function handleGetSession() {
  return {};
  // const isValidToken = handleValidateToken();

  // if (isValidToken) {
  //   try {
  // const idTokenFromLocalStorage = localStorage.getItem('id_token');
  //     return ''; // jwt.decode(idTokenFromLocalStorage);
  //   } catch (e) {
  //     return null;
  //   }
  // }
  // return null;
}

export {
  handleLogin,
  handleLogout,
  handleClearTokens,
  handleValidateToken,
  handleGetSession,
};
