import styled, { css } from 'styled-components';
import colors from 'styles/colors';

export const Favorites = styled.div`
  background: ${colors.gray_1};
  padding: 20px 0;
  min-height: calc(100vh - 56px);
  width: 100%;
  ${(props) =>
    props.isEmpty &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `};
`;

export const Empty = styled.div`
  text-align: center;

  > aside {
    width: 64px;
    height: 64px;
    border: 3px solid ${colors.gray_2};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto;
  }
  p {
    font-size: 18px;
    padding: 15px 0;
  }
  small {
    font-size: 14px;
    line-height: 1.2;
  }
`;
