import styled from 'styled-components';
import {
  compose,
  space,
  background,
  layout,
  color,
  flexbox,
  border,
  position,
  typography,
  system,
} from 'styled-system';

const Box = styled.div(
  compose(
    space,
    typography,
    background,
    layout,
    color,
    flexbox,
    border,
    position,
  ),
  system({
    transition: true,
    transform: true,
    boxShadow: true,
  }),
);

export default Box;
