import React from 'react';
import { Carousel } from 'antd';
import onboarding1 from 'images/register1.png';
import onboarding2 from 'images/register2.png';
import onboarding3 from 'images/register3.png';

import * as S from './styles';

const SETTINGS = {
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  swipeToSlide: true,
  draggable: true,
};

function OnboardingWrapper() {
  return (
    <S.OnboardingSlider>
      <section>
        <Carousel {...SETTINGS}>
          <S.SliderItem>
            <img src={onboarding1} alt="" />
            <h3>Detect and Notify</h3>
            <p>
              Upload images to our AI engine and get notified when an image
              triggers your rules.
            </p>
          </S.SliderItem>
          <S.SliderItem>
            <img src={onboarding2} alt="" />
            <h3>
              Access images from your camera devices anywhere and anytime.
            </h3>
            <p>
              Receive notifications and view images on any smart device 24/7
            </p>
          </S.SliderItem>
          <S.SliderItem>
            <img src={onboarding3} alt="" />
            <h3>Try out our smoke and fire detection </h3>
            <p>We can use your images to detect fire and smoke.</p>
          </S.SliderItem>
        </Carousel>
      </section>
    </S.OnboardingSlider>
  );
}

export default OnboardingWrapper;
