import styled from 'styled-components';
import colors from 'styles/colors';

export const Devices = styled.div`
  width: 100%;
  background: ${colors.gray_1};
  padding: 20px;
  min-height: calc(100vh - 119px);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 83px;
`;

export const Device = styled.div`
  margin-bottom: 30px;
  overflow: hidden;
  .device-name {
    padding: 0 0 10px;
  }
  .item {
    height: 262px;
    background: #fff;
    border-radius: 8px;
  }
  .slick-slider {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
  .slick-slide {
    padding: 0 10px;
  }
`;

export const DeviceItem = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 15px 20px;
`;

export const Thumbnail = styled.figure`
  height: 177px;
  width: 100%;
  background: ${colors.gray};
  margin: 10px 0;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const UserAvatar = styled.figure`
  width: 24px;
  height: 24px;
  background: ${colors.gray};
  border-radius: 50%;
`;
