import React from 'react';
import DeviceList from 'features/DeviceList';

import * as S from './styles';

function DeviceListPage() {
  return (
    <S.DeviceListPage>
      <DeviceList />
    </S.DeviceListPage>
  );
}

export default DeviceListPage;
