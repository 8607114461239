import React, { useMemo, useRef, useState } from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { createAuthenticatedAPI } from 'app/client';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { selectDeviceParameters } from './slice';

async function fetchAIParameters(search) {
  const api = createAuthenticatedAPI();
  return api.get(`/device/detections/list?search=${search}`).then((results) => {
    return results.map((parameter) => ({
      label: parameter,
      value: parameter,
    }));
  });
}

function DebounceSelectAIParameters({ debounceTimeout = 800, ...props }) {
  const deviceParameters = useSelector(selectDeviceParameters);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchAIParameters(value).then((availableParameterOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        if (deviceParameters.length > 0) {
          const selectedParameters = deviceParameters.map((parameter) => {
            return {
              label: parameter.name,
              value: parameter.name,
            };
          });

          setOptions([...availableParameterOptions, ...selectedParameters]);
        } else {
          setOptions(availableParameterOptions);
        }

        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout, deviceParameters]);

  useEffectOnce(() => {
    debounceFetcher('vehicle,person');
  });

  return (
    <Select
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

export default DebounceSelectAIParameters;
