import styled from 'styled-components';

import Box from 'components/Box';

export const StyledDeviceSubscription = styled(Box)`
  padding: 30px;
  width: 100%;

  .credit-card-box {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 8px;
  }
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  aside {
    flex: 0 0 200px;
  }

  h4 {
    text-transform: capitalize;
  }
`;
