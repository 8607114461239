import React, { useState } from 'react';
import {
  Space,
  List,
  Spin,
  Skeleton,
  Avatar,
  Button,
  Switch,
  Row,
  Col,
  Form,
} from 'antd';
import { useUpdateEffect, useEffectOnce } from 'react-use';
import { EllipsisOutlined, VideoCameraOutlined } from '@ant-design/icons';
import Box from 'components/Box';
import Text from 'components/Text';
import styled from 'styled-components';
import useDeviceSharing from '../hooks';

const StyledManageSharedUsers = styled(Form)``;

function Manage(props) {
  const { deviceId } = props;
  const [form] = Form.useForm();
  const [selectedUser, setSelectedUser] = useState('');

  const {
    getDeviceUserPermission,
    getDeviceUsers,
    removeDeviceUsers,
    deviceUserPermission,
    deviceUsers,
    updateDeviceUserPermission,
    pendingUpdateDeviceUserPermission,
    pendingRemoveDeviceUser,
  } = useDeviceSharing();

  const handleRemoveUser = (userId) => {
    removeDeviceUsers(deviceId, userId).then(async (res) => {
      const {
        meta: { requestStatus },
        payload,
      } = res;
      if (requestStatus === 'fulfilled' && payload) {
        await getDeviceUsers(deviceId);
        setSelectedUser('');
      }
    });
  };

  const handleUpdatePermission = (values) => {
    updateDeviceUserPermission(deviceId, selectedUser, values);
  };

  useUpdateEffect(() => {
    const { allowDownload, allowManageUser, allowConfigureAi } =
      deviceUserPermission;
    form.setFieldsValue({
      allowDownload: allowDownload === 1,
      allowManageUser: allowManageUser === 1,
      allowConfigureAI: allowConfigureAi === 1,
    });
  }, [deviceUserPermission]);

  useUpdateEffect(() => {
    if (deviceId) {
      getDeviceUsers(deviceId);
    }
  }, [deviceId]);

  useEffectOnce(() => {
    if (deviceId) {
      getDeviceUsers(deviceId);
    }
  });

  useUpdateEffect(() => {
    if (selectedUser !== '') {
      getDeviceUserPermission(deviceId, selectedUser);
    }
  }, [selectedUser]);

  return (
    <StyledManageSharedUsers form={form} onFinish={handleUpdatePermission}>
      {selectedUser === '' ? (
        <div className="scrollbar h-[calc(50vh-130px)] overflow-y-scroll pr-[10px]">
          <Spin spinning={false}>
            <List
              dataSource={deviceUsers}
              renderItem={(item) => (
                <List.Item>
                  <Skeleton avatar title={false} loading={false} active>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={
                            item.profileAvatar
                              ? item.profileAvatar
                              : `https://api.multiavatar.com/${item.profileEmail}.svg?apikey=DyHpm1NoFUusV5`
                          }
                        />
                      }
                      title={item.profileName}
                      description={item.profileEmail}
                    />
                    <Space size={20}>
                      {/* <p>Shared in {item.sharedDeviceCount} Devices</p> */}
                      {/* <Button type="default" onClick={() => setSelectedUser(item.id)}>Manage</Button> */}
                      <Button
                        loading={pendingRemoveDeviceUser}
                        type="default"
                        onClick={() => handleRemoveUser(item.id)}
                      >
                        Remove User
                      </Button>
                    </Space>
                  </Skeleton>
                </List.Item>
              )}
            />
          </Spin>
        </div>
      ) : (
        <Row>
          <Col span={24}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mb={4}
            >
              <Box display="flex" alignItems="center">
                <Avatar
                  src={`https://api.multiavatar.com/${deviceUserPermission.email}.svg?apikey=DyHpm1NoFUusV5`}
                />
                <Box ml={2}>
                  <Text fontWeight={500}>{deviceUserPermission.name}</Text>
                  <Text fontSize={12} color="gray_2">
                    {deviceUserPermission.email}
                  </Text>
                </Box>
              </Box>
              <Button
                loading={pendingRemoveDeviceUser}
                type="default"
                onClick={handleRemoveUser}
              >
                Remove User
              </Button>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mb={2}
            >
              <Box>
                <Text fontWeight={500}>Download Photos</Text>
                <Text fontSize={12} color="gray_2">
                  Allow this user to download photos from camera and gallery.
                </Text>
              </Box>
              <Form.Item name="allowDownload" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mb={2}
            >
              <Box>
                <Text fontWeight={500}>Manage Users</Text>
                <Text fontSize={12} color="gray_2">
                  Allow this user to share and manage this camera with other
                  users.
                </Text>
              </Box>
              <Form.Item name="allowManageUser" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mb={2}
            >
              <Box>
                <Text fontWeight={500}>Configure AI Parameters</Text>
                <Text fontSize={12} color="gray_2">
                  Allow this user to modify and configure AI Parameters.
                </Text>
              </Box>
              <Form.Item name="allowConfigureAI" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Box>
            <Text fontWeight={500} fontSize={15} mt={4} mb={2}>
              Device Shared
            </Text>
            <List
              size="small"
              bordered
              dataSource={deviceUserPermission.sharedDevices}
              renderItem={(item) => (
                <List.Item>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={1}
                    width="100%"
                  >
                    <Box display="flex" alignItems="Center">
                      <VideoCameraOutlined style={{ fontSize: '18px' }} />
                      <Text ml={2}>{item.deviceName}</Text>
                    </Box>
                    <EllipsisOutlined style={{ fontSize: '21px' }} />
                  </Box>
                </List.Item>
              )}
            />
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <Space>
                <Button size="large" onClick={() => setSelectedUser('')}>
                  Back
                </Button>
                <Button
                  htmlType="submit"
                  loading={pendingUpdateDeviceUserPermission}
                  type="primary"
                  size="large"
                >
                  Save Changes
                </Button>
              </Space>
            </Box>
          </Col>
        </Row>
      )}
    </StyledManageSharedUsers>
  );
}

export default Manage;
