import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Space,
  Typography,
  Button,
  message,
  Col,
  Form,
  Input,
  Row,
  notification,
} from 'antd';
import { DollarOutlined } from '@ant-design/icons';

import Box from 'components/Box';

import { createAuthenticatedAPI } from 'app/client';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

const { Title, Text } = Typography;
const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#5466E0',
      color: 'rgba(0, 0, 0, 0.85)',
      fontSize: '14px',
    },
    invalid: {
      iconColor: '#f74242',
      color: '#f74242',
    },
  },
};

export const StyledPaymentMethods = styled(Box)`
  .credit-card-box {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 8px;
    margin-bottom: 20px;
  }
`;

const REQUIRED = [{ required: true, message: 'This field is required!' }];

function PaymentMethods() {
  const elements = useElements();
  const stripe = useStripe();
  const [form] = Form.useForm();

  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleAddNewCard = () => {
    form.validateFields().then(async (cardDetails) => {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      message.loading('Adding new card...', 0);

      if (error) {
        elements.getElement('card').focus();
        return;
      }

      if (cardComplete) {
        setProcessing(true);
      }

      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: cardDetails.nameOnCard,
          phone: cardDetails.phone,
          email: cardDetails.email,
        },
      });

      setProcessing(false);

      if (payload.error) {
        setError(payload.error);
      } else {
        const { paymentMethod } = payload;
        const paymentMethodId = paymentMethod.id;

        const api = createAuthenticatedAPI();
        await api.post('billing/cards', {
          newPaymentMethodId: paymentMethodId,
        });

        notification.success({
          message: 'New Credit Card',
          description: 'New card added successfully.',
          placement: 'bottomLeft',
        });
        form.resetFields();
        elements.getElement(CardElement).clear();
        message.destroy();
      }
    });
  };

  return (
    <StyledPaymentMethods p={{ _: 20, sm: 30 }}>
      <Space style={{ margin: '0 0 20px 0' }}>
        <DollarOutlined style={{ fontSize: '21px' }} />
        <Title level={4} style={{ margin: '0' }}>
          Payment Methods
        </Title>
      </Space>
      <Form form={form} layout="vertical">
        <Row>
          <Col span={13}>
            <Form.Item label="Name on Card" name="nameOnCard" rules={REQUIRED}>
              <Input />
            </Form.Item>
            <Form.Item label="Phone" name="phone" rules={REQUIRED}>
              <Input />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={REQUIRED}>
              <Input type="email" />
            </Form.Item>
          </Col>
          <Col span={13} style={{ marginBottom: '5px' }}>
            <Text>Card Details</Text>
          </Col>
          <Col span={13} className="credit-card-box">
            <CardElement
              options={CARD_OPTIONS}
              onChange={(e) => {
                setError(e.error);
                setCardComplete(e.complete);
              }}
            />
          </Col>
          <Col span={13}>
            <Button
              type="primary"
              onClick={handleAddNewCard}
              loading={processing}
            >
              Add New Card
            </Button>
          </Col>
        </Row>
      </Form>
    </StyledPaymentMethods>
  );
}

export default PaymentMethods;
