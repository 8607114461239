import styled from 'styled-components';
import colors from 'styles/colors';

export const DeviceDetail = styled.div`
  display: flex;
  width: 100%;
  background: ${colors.gray_1};
  padding: 0;
  min-height: calc(100vh - 56px);
  position: relative;
  height: 100%;
  @media (max-width: 1200px) {
    flex-direction: column;
  }

  > section {
    flex: 1;
    background: ${colors.gray_1};
    padding: 20px;
    @media (max-width: 600px) {
      padding: 10px;
    }
  }
  > aside {
    flex: 0 0 400px;
    background: ${colors.white};
    padding: 10px 15px;

    @media (max-width: 1200px) {
      flex: 1;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    margin-bottom: 10px;
  }

  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${colors.gray};
  }
`;

export const Thumbnail = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background: ${colors.white};

  > figure {
    position: relative;
    max-width: 900px;
    margin: 20px auto;
    .rectangle {
      position: absolute;
      border: 3px solid red;
      z-index: 2;
    }

    .ant-image {
      display: block;
    }
    img {
      width: 100%;
    }
  }
  > section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    aside {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > p {
        margin-left: 10px;
      }
    }
  }
`;

export const Photos = styled.div`
  .ant-pagination-simple .ant-pagination-simple-pager input {
    padding: 0;
    width: 45px;
  }

  .photo-list-wrapper {
    height: calc(100vh - 170px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 4px;
    margin-top: 5px;

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dedede;
    }
  }

  .item {
    border-bottom: 1px solid ${colors.gray_1};
    img {
      width: 100%;
    }
  }

  .ant-image {
    width: 100%;
  }
`;
