import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';

import 'normalize.css';
import './theme/tailwind.css';
import './theme/global.css';

import App from 'app';
import store from 'app/store';

import antTheme from 'theme/antd';
import theme from 'styles/theme';

import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line func-names
export default function () {
  ReactDOM.render(
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid:profile',
      }}
    >
      <Provider store={store()}>
        <ConfigProvider theme={antTheme}>
          <Router>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </Router>
        </ConfigProvider>
      </Provider>
    </Auth0Provider>,
    document.getElementById('root'),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
